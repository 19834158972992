import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Switch } from "@/components/ui/switch";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Plus, Trash2, GripVertical } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { DynamicQuoteForm } from './DynamicQuoteForm';

const embedSettingsSchema = z.object({
  formTitle: z.string().min(1, "Form title is required"),
  primaryColor: z.string().regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid color hex code"),
  theme: z.enum(["light", "dark", "auto"]),
  customCss: z.string().optional(),
  formFields: z.array(z.object({
    id: z.string(),
    type: z.enum(['text', 'email', 'tel', 'textarea', 'select', 'number']),
    label: z.string(),
    placeholder: z.string().optional(),
    required: z.boolean().optional(),
    options: z.array(z.object({
      label: z.string(),
      value: z.string()
    })).optional(),
  })),
});

type EmbedSettingsForm = z.infer<typeof embedSettingsSchema>;

const defaultFormFields = [
  {
    id: 'name',
    type: 'text' as const,
    label: 'Full Name',
    placeholder: 'Enter your full name',
    required: true,
  },
  {
    id: 'email',
    type: 'email' as const,
    label: 'Email',
    placeholder: 'Enter your email',
    required: true,
  },
  {
    id: 'phone',
    type: 'tel' as const,
    label: 'Phone',
    placeholder: 'Enter your phone number',
    required: false,
  },
  {
    id: 'projectDetails',
    type: 'textarea' as const,
    label: 'Project Details',
    placeholder: 'Describe your project',
    required: true,
  },
];

export function EmbedSettings() {
  const { toast } = useToast();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [embedCode, setEmbedCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const form = useForm<EmbedSettingsForm>({
    resolver: zodResolver(embedSettingsSchema),
    defaultValues: {
      formTitle: "Get an Instant Quote",
      primaryColor: "#0066FF",
      theme: "light",
      customCss: "",
      formFields: defaultFormFields,
    },
  });

  useEffect(() => {
    const fetchSettings = async () => {
      console.log('Fetching settings...');
      try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/vendor/settings`, {
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch settings');
        }

        const { data, apiKey } = await response.json();
        if (data) {
          form.reset({
            formTitle: data.formTitle || "Get an Instant Quote",
            primaryColor: data.primaryColor || "#0066FF",
            theme: data.theme || "light",
            customCss: data.customCss || "",
            formFields: data.formFields || defaultFormFields,
          });
          
          setFormFields(data.formFields || defaultFormFields);
          
          if (apiKey) {
            setEmbedCode(`
<div id="proposal-stream-container"></div>
<script>
  window.ProposalStreamConfig = {
    apiKey: '${apiKey}',
    containerId: 'proposal-stream-container'
  };
</script>
<script src="${window.location.origin}/embed.js" async></script>
            `.trim());
          }
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
        toast({
          title: "Error",
          description: "Failed to load settings",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const onSubmit = async (data: EmbedSettingsForm) => {
    try {
      console.log('Submitting settings:', data);
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/vendor/settings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to save settings");
      }

      const result = await response.json();
      
      if (result.status === "success") {
        const embedCode = `
<div id="proposal-stream-container"></div>
<script>
  window.ProposalStreamConfig = {
    apiKey: '${result.apiKey}',
    containerId: 'proposal-stream-container'
  };
</script>
<script src="${window.location.origin}/embed.js" async></script>
        `.trim();

        setEmbedCode(embedCode);

        toast({
          title: "Settings saved",
          description: "Your embed code has been updated.",
        });
      } else {
        throw new Error(result.message || "Failed to save settings");
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to save settings",
        variant: "destructive",
      });
    }
  };

  const addField = () => {
    const newField = {
      id: `field_${Date.now()}`,
      type: 'text',
      label: 'New Field',
      placeholder: '',
      required: false,
    };
    const updatedFields = [...formFields, newField];
    setFormFields(updatedFields);
    form.setValue('formFields', updatedFields);
  };

  const removeField = (index: number) => {
    const updatedFields = formFields.filter((_, i) => i !== index);
    setFormFields(updatedFields);
    form.setValue('formFields', updatedFields);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    
    const items = Array.from(formFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setFormFields(items);
    form.setValue('formFields', items);
  };

  return (
    <div className="space-y-6">
      {isLoading ? (
        <div className="flex items-center justify-center p-6">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      ) : (
        <Tabs defaultValue="settings" className="w-full">
          <TabsList>
            <TabsTrigger value="settings">Form Settings</TabsTrigger>
            <TabsTrigger value="fields">Form Fields</TabsTrigger>
            <TabsTrigger value="preview">Preview</TabsTrigger>
            <TabsTrigger value="embed">Embed Code</TabsTrigger>
          </TabsList>

          <TabsContent value="settings">
            <Card className="p-6">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                  <FormField
                    control={form.control}
                    name="formTitle"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Form Title</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormDescription>
                          This will be displayed at the top of your quote form.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="primaryColor"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Primary Color</FormLabel>
                        <FormControl>
                          <div className="flex gap-2">
                            <Input {...field} type="color" className="w-12 h-10" />
                            <Input {...field} placeholder="#000000" />
                          </div>
                        </FormControl>
                        <FormDescription>
                          Choose the main color for your form elements.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="theme"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Theme</FormLabel>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select theme" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="light">Light</SelectItem>
                            <SelectItem value="dark">Dark</SelectItem>
                            <SelectItem value="auto">Auto (System)</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormItem>
                    )}
                  />

                  <Button type="submit">
                    Save Settings
                  </Button>
                </form>
              </Form>
            </Card>
          </TabsContent>

          <TabsContent value="fields">
            <Card className="p-6">
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold">Form Fields</h3>
                  <Button onClick={addField} size="sm">
                    <Plus className="w-4 h-4 mr-2" /> Add Field
                  </Button>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="fields">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {formFields.map((field, index) => (
                          <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="flex items-center gap-4 p-4 mb-2 bg-card border rounded-lg"
                              >
                                <div {...provided.dragHandleProps}>
                                  <GripVertical className="w-5 h-5 text-muted-foreground" />
                                </div>
                                
                                <div className="flex-1 grid grid-cols-2 gap-4">
                                  <Input
                                    value={field.label}
                                    onChange={(e) => {
                                      const updatedFields = [...formFields];
                                      updatedFields[index].label = e.target.value;
                                      setFormFields(updatedFields);
                                      form.setValue('formFields', updatedFields);
                                    }}
                                    placeholder="Field Label"
                                  />
                                  
                                  <Select
                                    value={field.type}
                                    onValueChange={(value) => {
                                      const updatedFields = [...formFields];
                                      updatedFields[index].type = value as any;
                                      setFormFields(updatedFields);
                                      form.setValue('formFields', updatedFields);
                                    }}
                                  >
                                    <SelectTrigger>
                                      <SelectValue placeholder="Field Type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectItem value="text">Text</SelectItem>
                                      <SelectItem value="email">Email</SelectItem>
                                      <SelectItem value="tel">Phone</SelectItem>
                                      <SelectItem value="textarea">Text Area</SelectItem>
                                      <SelectItem value="select">Select</SelectItem>
                                      <SelectItem value="number">Number</SelectItem>
                                    </SelectContent>
                                  </Select>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </Card>
          </TabsContent>

          <TabsContent value="preview">
            <Card className="p-6">
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Form Preview</h3>
                <div className="border rounded-lg p-4">
                  <DynamicQuoteForm
                    settings={{
                      formTitle: form.getValues('formTitle'),
                      primaryColor: form.getValues('primaryColor'),
                      theme: form.getValues('theme'),
                      customCss: form.getValues('customCss'),
                      formFields: form.getValues('formFields'),
                    }}
                    onSubmit={async (data) => {
                      console.log('Preview form submission:', data);
                      toast({
                        title: "Test Submission",
                        description: "This is a preview - form submissions are disabled.",
                      });
                    }}
                  />
                </div>
              </div>
            </Card>
          </TabsContent>

          <TabsContent value="embed">
            <Card className="p-6">
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Embed Code</h3>
                {embedCode ? (
                  <>
                    <p className="text-sm text-muted-foreground">
                      Copy and paste this code into your website where you want the form to appear:
                    </p>
                    <div className="relative">
                      <pre className="bg-muted p-4 rounded-lg overflow-x-auto">
                        <code>{embedCode}</code>
                      </pre>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="absolute top-2 right-2"
                        onClick={() => {
                          navigator.clipboard.writeText(embedCode);
                          toast({
                            title: "Copied!",
                            description: "Embed code copied to clipboard",
                          });
                        }}
                      >
                        Copy
                      </Button>
                    </div>
                  </>
                ) : (
                  <p className="text-sm text-muted-foreground">
                    Save your form settings to get the embed code.
                  </p>
                )}
              </div>
            </Card>
          </TabsContent>
        </Tabs>
      )}
    </div>
  );
}
