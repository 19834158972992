import useSWR from "swr";
import { useToast } from "@/hooks/use-toast";
import type { User } from "../../../server/db/schema";
import { FetchError } from "@/lib/fetcher";

interface AuthResponse {
  status: "success" | "error";
  user?: User;
  error?: string;
  message?: string;
}

interface RegisterData {
  email: string;
  password: string;
  name: string;
}

interface LoginData {
  email: string;
  password: string;
}

const ALLOWED_ORIGINS = [
  'http://localhost:5173',
  'http://localhost:4000'
];

export function useUser() {
  const { data, error, mutate } = useSWR<AuthResponse>("/api/auth/user", async (url) => {
    try {
      const response = await fetch(url, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.status === 401) {
        return { status: "success", user: undefined };
      }
      
      if (!response.ok) {
        const info = await response.json();
        throw new FetchError(
          info.message || 'Failed to fetch user',
          info,
          response.status
        );
      }
      
      return response.json();
    } catch (error) {
      if (error instanceof FetchError) {
        throw error;
      }
      throw new FetchError(
        error instanceof Error ? error.message : 'Failed to fetch user',
        error,
        500
      );
    }
  }, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnReconnect: false
  });

  const { toast } = useToast();

  const handleGoogleLogin = async (credential: string) => {
    try {
      console.log('Starting Google login process...');
      
      const response = await fetch('/api/auth/google', {
        method: "POST",
        credentials: 'include',
        headers: { 
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest"
        },
        body: JSON.stringify({ 
          token: credential,
          origin: window.location.origin
        }),
      });

      console.log('Google auth response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Google auth error:', errorData);
        throw new Error(errorData.message || 'Authentication failed');
      }

      const result = await response.json();
      console.log('Google auth result:', result);
      
      if (result.status === "error") {
        throw new Error(result.message || "Login failed");
      }

      // Wait longer for session establishment
      console.log('Waiting for session to establish...');
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // First mutation to update local state
      await mutate();

      // Multiple verification attempts
      for (let i = 0; i < 3; i++) {
        console.log(`Verification attempt ${i + 1}...`);
        
        const userCheck = await fetch('/api/auth/user', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',  // Prevent caching
            'Pragma': 'no-cache'
          }
        });
        
        console.log('User check status:', userCheck.status);
        
        if (userCheck.ok) {
          const userData = await userCheck.json();
          console.log('User data:', userData);
          
          if (userData.user) {
            toast({
              title: "Success",
              description: "Successfully signed in with Google",
            });
            
            // Force a complete page reload to reset all states
            window.location.href = '/dashboard';
            return;
          }
        }
        
        // Wait before next attempt
        await new Promise(resolve => setTimeout(resolve, 500));
      }

      throw new Error("Failed to verify user session after multiple attempts");
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: "Authentication Error",
        description: error instanceof Error ? error.message : "Failed to authenticate with Google",
        variant: "destructive",
      });
      throw error;
    }
  };

  const register = async (data: RegisterData) => {
    try {
      const response = await fetch('/api/auth/register', {
        method: "POST",
        credentials: 'include',
        headers: { 
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message || "Registration failed");
      }

      await mutate();
      toast({
        title: "Success",
        description: "Account created successfully",
      });
      
      return result;
    } catch (error) {
      console.error('Registration error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to register",
        variant: "destructive",
      });
      throw error;
    }
  };

  const login = async (data: LoginData) => {
    try {
      const response = await fetch('/api/auth/login', {
        method: "POST",
        credentials: 'include',
        headers: { 
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message || "Login failed");
      }

      await mutate();
      toast({
        title: "Success",
        description: "Successfully signed in",
      });
      
      return result;
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to log in",
        variant: "destructive",
      });
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await fetch('/api/auth/logout', {
        method: "POST",
        credentials: 'include',
      });
      
      const result = await response.json();
      
      if (result.status === "error") {
        throw new Error(result.message || "Logout failed");
      }
      
      await mutate(undefined);
      toast({
        title: "Success",
        description: "Logged out successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to log out",
        variant: "destructive",
      });
    }
  };

  return {
    user: data?.user,
    isLoading: !error && !data,
    error: error instanceof FetchError ? error.info?.message || error.message : error?.message,
    handleGoogleLogin,
    register,
    login,
    logout,
  };
}
