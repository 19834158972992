import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";

interface Service {
  id: number;
  title: string;
  description: string;
  features: string[];
  category: string;
}

const services: Service[] = [
  {
    id: 1,
    title: "Quote Automation System",
    description: "Streamline your quoting process with AI-powered automation, enabling faster, more accurate client responses.",
    features: [
      "Automated quote generation in under 2 minutes",
      "Website integration for seamless use",
      "Custom service templates tailored to your business",
      "Real-time quote tracking dashboard",
      "Detailed analytics to measure success",
      "Mobile-friendly client portal"
    ],
    category: "Automation"
  },
  {
    id: 2,
    title: "CRM Integration",
    description: "Streamline your customer relationships with powerful CRM solutions, ready for future proposal management features.",
    features: [
      "Popular CRM integrations",
      "Data migration",
      "Workflow automation",
      "Team training",
      "Custom reporting",
      "Future proposal management integration"
    ],
    category: "Business Solutions"
  },
  {
    id: 3,
    title: "Custom Development",
    description: "Tailored software solutions designed for your specific business needs, with upcoming contract management capabilities.",
    features: [
      "Custom tools",
      "Rapid development",
      "Focused solutions",
      "Ongoing support",
      "Contract management ready",
      "Future-proof design"
    ],
    category: "Development"
  },
  {
    id: 4,
    title: "Website Refresh & Revamp",
    description: "Transform your digital presence with our comprehensive website modernization services.",
    features: [
      "Modern design refresh",
      "Mobile responsiveness",
      "Lead capture optimization",
      "Professional branding",
      "SEO optimization",
      "Performance tuning"
    ],
    category: "Web Development"
  },
  {
    id: 5,
    title: "Database Management",
    description: "Optimize your data infrastructure for better performance and scalability.",
    features: [
      "Setup & optimization",
      "Ongoing maintenance",
      "Performance monitoring",
      "Scalability planning",
      "Security hardening",
      "Backup solutions"
    ],
    category: "Infrastructure"
  },
  {
    id: 6,
    title: "Tech-Aid Kit",
    description: "Your monthly technical support solution for ongoing maintenance and updates.",
    features: [
      "Regular updates",
      "Bug fixes",
      "Performance monitoring",
      "Priority support",
      "Security patches",
      "Monthly reports"
    ],
    category: "Support"
  }
];

const ServiceCard = ({ service }: { service: Service }) => {
  return (
    <Card className="p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
      <div>
        <h3 className="text-2xl font-bold mb-3">{service.title}</h3>
        <p className="text-muted-foreground mb-6">{service.description}</p>
        <div className="space-y-3 mb-6">
          {service.features.map((feature, index) => (
            <div key={index} className="flex items-center gap-2">
              <Check className="h-5 w-5 text-primary flex-shrink-0" />
              <span className="text-muted-foreground">{feature}</span>
            </div>
          ))}
        </div>
        <p className="text-sm text-muted-foreground">Category: {service.category}</p>
      </div>
    </Card>
  );
};

interface ServiceOfferingsProps {
  showAll?: boolean;
}

export function ServiceOfferings({ showAll = false }: ServiceOfferingsProps) {
  const displayServices = services;
  
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      {displayServices.map((service) => (
        <ServiceCard key={service.id} service={service} />
      ))}
    </div>
  );
}