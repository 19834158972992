import useSWR, { mutate } from "swr";
import type { Proposal } from "../../../server/db/schema";

interface ProposalResponse {
  status: "success" | "error";
  data: Proposal | Proposal[] | null;
  message?: string;
}

const CACHE_KEY = "/api/proposals";

export function useProposals() {
  const { data: response, error, isLoading } = useSWR<ProposalResponse>(
    CACHE_KEY,
    {
      revalidateOnFocus: false,
      dedupingInterval: 5000,
      shouldRetryOnError: true,
      errorRetryCount: 3
    }
  );

  return {
    proposals: Array.isArray(response?.data) ? response.data : [],
    isLoading: !error && !response,
    error,
    metadata: response ? {
      status: response.status,
      message: response.message
    } : null
  };
}

export function useProposal(id?: number) {
  const proposalKey = id ? `${CACHE_KEY}/${id}` : null;
  
  const { data: response, error, isLoading } = useSWR<ProposalResponse>(
    proposalKey,
    {
      revalidateOnFocus: false,
      dedupingInterval: 5000,
      shouldRetryOnError: true,
      errorRetryCount: 3,
      onSuccess: (data) => {
        console.log('Successfully fetched proposal:', {
          id,
          status: data.status,
          timestamp: new Date().toISOString()
        });
      },
      onError: (err) => {
        console.error('Error fetching proposal:', {
          id,
          error: err.message,
          timestamp: new Date().toISOString()
        });
      }
    }
  );

  const handleResponse = async (response: Response): Promise<ProposalResponse> => {
    if (!response.ok) {
      let errorMessage = "An error occurred";
      let errorResponse: ProposalResponse = {
        status: "error",
        data: null,
        message: errorMessage
      };

      try {
        const contentType = response.headers.get("content-type");
        if (contentType?.includes("application/json")) {
          const errorData = await response.json();
          errorResponse = {
            status: "error",
            data: null,
            message: errorData.message || errorMessage
          };
        } else {
          const textError = await response.text();
          if (textError.includes("<!DOCTYPE html>")) {
            errorResponse.message = "Server error occurred";
          } else {
            errorResponse.message = textError || errorMessage;
          }
        }
      } catch (parseError) {
        console.error('Error parsing response:', parseError);
        errorResponse.message = "Failed to process server response";
      }
      
      throw new Error(errorResponse.message);
    }
    
    if (response.status === 204) {
      return {
        status: "success",
        data: null,
        message: "Operation completed successfully"
      };
    }

    const contentType = response.headers.get("content-type");
    if (!contentType?.includes("application/json")) {
      return {
        status: "success",
        data: null,
        message: "Operation completed successfully"
      };
    }

    const jsonResponse = await response.json();
    return {
      status: jsonResponse.status || "success",
      data: jsonResponse.data,
      message: jsonResponse.message
    };
  };

  let deleteInProgress = false;

  return {
    proposal: response?.data as Proposal | undefined,
    isLoading,
    error,
    async create(data: Partial<Proposal>) {
      try {
        console.log('Creating proposal:', {
          ...data,
          timestamp: new Date().toISOString(),
        });

        const response = await fetch(CACHE_KEY, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
        
        const result = await handleResponse(response);
        
        console.log('Successfully created proposal:', {
          id: result.data?.id,
          status: result.status,
          timestamp: new Date().toISOString()
        });

        await Promise.all([
          mutate(CACHE_KEY, undefined, true),
          id && mutate(proposalKey, undefined, true)
        ]);
        
        return result;
      } catch (error: any) {
        console.error('Error creating proposal:', {
          error: error.message,
          stack: error.stack,
          data: data,
          timestamp: new Date().toISOString(),
        });
        throw error;
      }
    },
    async update(id: number, data: Partial<Proposal>) {
      try {
        console.log('Updating proposal:', {
          id,
          ...data,
          timestamp: new Date().toISOString(),
        });

        const response = await fetch(`${CACHE_KEY}/${id}`, {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
        
        const result = await handleResponse(response);
        
        console.log('Successfully updated proposal:', {
          id: result.data?.id,
          status: result.status,
          timestamp: new Date().toISOString()
        });

        await Promise.all([
          mutate(CACHE_KEY, undefined, true),
          mutate(`${CACHE_KEY}/${id}`, undefined, true)
        ]);
        
        return result;
      } catch (error: any) {
        console.error('Error updating proposal:', {
          error: error.message,
          stack: error.stack,
          id,
          data: data,
          timestamp: new Date().toISOString(),
        });
        throw error;
      }
    },
    async delete(id: number) {
      if (deleteInProgress) {
        throw new Error("Delete operation already in progress");
      }

      try {
        deleteInProgress = true;

        // Optimistic update with proper array handling
        await mutate(
          CACHE_KEY,
          async (currentData?: ProposalResponse) => {
            if (!currentData?.data || !Array.isArray(currentData.data)) {
              return currentData;
            }
            return {
              ...currentData,
              data: currentData.data.filter(p => p.id !== id)
            };
          },
          { revalidate: false }
        );

        console.log('Deleting proposal:', {
          id,
          timestamp: new Date().toISOString(),
        });

        const response = await fetch(`${CACHE_KEY}/${id}`, {
          method: "DELETE",
        });
        
        const result = await handleResponse(response);
        
        console.log('Successfully deleted proposal:', {
          id,
          status: result.status,
          message: result.message,
          timestamp: new Date().toISOString()
        });

        // Force revalidation of both caches
        await Promise.all([
          mutate(CACHE_KEY, undefined, true),
          mutate(`${CACHE_KEY}/${id}`, undefined, true)
        ]);
        
        return result;
      } catch (error: any) {
        // Revert optimistic update and revalidate on error
        await Promise.all([
          mutate(CACHE_KEY, undefined, true),
          mutate(`${CACHE_KEY}/${id}`, undefined, true)
        ]);
        
        console.error('Error deleting proposal:', {
          error: error.message,
          stack: error.stack,
          id,
          timestamp: new Date().toISOString(),
        });
        throw error;
      } finally {
        deleteInProgress = false;
      }
    }
  };
}
