import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { 
  ArrowRight, 
  ArrowLeft, 
  Building2, 
  Wrench, 
  Rocket, 
  Eye, 
  Zap, 
  FileText, 
  Check,
  Code,
  Database,
  Globe,
  HelpCircle,
  Shield,
  BarChart
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "wouter";

interface Question {
  id: string;
  text: string;
  options: {
    text: string;
    icon?: JSX.Element;
    nextId?: string;
    action?: () => void;
  }[];
}

interface SignupData {
  email: string;
  name: string;
  companyName: string;
  phoneNumber?: string;
  city?: string;
  state?: string;
  country: string;
  otherCountry?: string;
  companySize?: string;
  productInterest: string;
}

const COMPANY_SIZES = [
  "1-10 employees",
  "11-50 employees",
  "51-200 employees",
  "201-500 employees",
  "501-1000 employees",
  "1001+ employees"
];

export function InteractiveQA() {
  const [currentQuestionId, setCurrentQuestionId] = useState("start");
  const [history, setHistory] = useState<string[]>([]);
  const [, navigate] = useLocation();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<SignupData>({
    email: "",
    name: "",
    companyName: "",
    phoneNumber: "",
    city: "",
    state: "",
    country: "United States",
    otherCountry: "",
    companySize: "",
    productInterest: ""
  });

  const handleInputChange = (field: keyof SignupData, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent, productTitle: string) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const submitData = {
        ...formData,
        country: formData.country === "Other" ? formData.otherCountry : formData.country,
        productInterest: productTitle
      };

      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'http://localhost:3000';
      const response = await fetch(`${backendUrl}/api/waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData),
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || 'Failed to join waitlist');
      }

      toast({
        title: "Success!",
        description: "Thank you for your interest. We'll be in touch soon!",
      });

      // Navigate to thank you page or reset form
      navigate('/thank-you');
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Something went wrong",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const questions: Record<string, Question> = {
    start: {
      id: "start",
      text: "What brings you here today?",
      options: [
        { 
          text: "Need to modernize our tech", 
          icon: <Code className="h-8 w-8" />,
          nextId: "tech-needs" 
        },
        { 
          text: "Looking for specific solutions", 
          icon: <Wrench className="h-8 w-8" />,
          nextId: "specific-solutions" 
        },
        { 
          text: "Not sure where to start", 
          icon: <HelpCircle className="h-8 w-8" />,
          nextId: "overwhelmed" 
        }
      ]
    },
    "tech-needs": {
      id: "tech-needs",
      text: "What's your main priority?",
      options: [
        { 
          text: "Website Improvement", 
          icon: <Globe className="h-8 w-8" />,
          nextId: "website-solutions" 
        },
        { 
          text: "Data Management", 
          icon: <Database className="h-8 w-8" />,
          nextId: "data-solutions" 
        },
        { 
          text: "Business Growth", 
          icon: <BarChart className="h-8 w-8" />,
          nextId: "growth-solutions" 
        },
        { 
          text: "Security & Infrastructure", 
          icon: <Shield className="h-8 w-8" />,
          nextId: "security-solutions" 
        }
      ]
    },
    "specific-solutions": {
      id: "specific-solutions",
      text: "Which service interests you?",
      options: [
        { 
          text: "Website Refresh & Revamp",
          nextId: "website-refresh"
        },
        { 
          text: "CRM Integration",
          nextId: "crm-integration"
        },
        { 
          text: "Quote Response System",
          nextId: "quote-system"
        },
        { 
          text: "Monthly Tech Support",
          nextId: "tech-support"
        },
        {
          text: "See all services",
          icon: <ArrowRight className="h-8 w-8" />,
          action: () => navigate('/products')
        }
      ]
    },
    "overwhelmed": {
      id: "overwhelmed",
      text: "No worries! Let's figure this out together.",
      options: [
        { 
          text: "Book a free consultation", 
          icon: <Check className="h-8 w-8" />,
          action: () => navigate('/book-call')
        },
        { 
          text: "Browse our services first", 
          icon: <Eye className="h-8 w-8" />,
          action: () => navigate('/products')
        },
        { 
          text: "Tell us about your business", 
          icon: <FileText className="h-8 w-8" />,
          nextId: "business-type" 
        }
      ]
    },
    "business-type": {
      id: "business-type",
      text: "What type of business do you run?",
      options: [
        { 
          text: "Property Management", 
          icon: <Building2 className="h-8 w-8" />,
          nextId: "recommend-consultation" 
        },
        { 
          text: "Vendor/Service Provider", 
          icon: <Wrench className="h-8 w-8" />,
          nextId: "recommend-consultation" 
        },
        { 
          text: "Other", 
          icon: <Rocket className="h-8 w-8" />,
          nextId: "recommend-consultation" 
        }
      ]
    }
  };

  const services = [
    {
      id: 1,
      title: "Website Refresh & Revamp",
      description: "Transform your digital presence with our comprehensive website modernization services.",
      features: [
        "Modern design refresh",
        "Mobile responsiveness",
        "Lead capture optimization",
        "Professional branding"
      ],
      category: "Web Development"
    },
    {
      id: 2,
      title: "CRM Integration",
      description: "Streamline your customer relationships with powerful CRM solutions.",
      features: [
        "Popular CRM integrations",
        "Data migration",
        "Workflow automation",
        "Team training"
      ],
      category: "Business Solutions"
    },
    {
      id: 3,
      title: "Database Management",
      description: "Optimize your data infrastructure for better performance and scalability.",
      features: [
        "Setup & optimization",
        "Ongoing maintenance",
        "Performance monitoring",
        "Scalability planning"
      ],
      category: "Infrastructure"
    },
    {
      id: 4,
      title: "Quote Response System",
      description: "Automate your quote generation process for faster client responses.",
      features: [
        "Automated generation",
        "Website integration",
        "Custom templates",
        "Analytics dashboard"
      ],
      category: "Automation"
    },
    {
      id: 5,
      title: "Tech-Aid Kit",
      description: "Your monthly technical support solution for ongoing maintenance and updates.",
      features: [
        "Regular updates",
        "Bug fixes",
        "Performance monitoring",
        "Priority support"
      ],
      category: "Support"
    },
    {
      id: 6,
      title: "Custom Development",
      description: "Tailored software solutions designed for your specific business needs.",
      features: [
        "Custom tools",
        "Rapid development",
        "Focused solutions",
        "Ongoing support"
      ],
      category: "Development"
    }
  ];

  const results: Record<string, { title: string; description: JSX.Element }> = {
    "website-solutions": {
      title: "Website Refresh & Revamp",
      description: (
        <div className="space-y-4">
          <p>Let's modernize your website to meet today's standards:</p>
          <ul className="list-disc list-inside space-y-2 text-left">
            <li>Enhance user experience</li>
            <li>Improve conversion rates</li>
            <li>Strengthen your brand</li>
          </ul>
        </div>
      )
    },
    "data-solutions": {
      title: "Database Management",
      description: (
        <div className="space-y-4">
          <p>Optimize your data infrastructure:</p>
          <ul className="list-disc list-inside space-y-2 text-left">
            <li>Improve performance</li>
            <li>Ensure scalability</li>
            <li>Maintain data integrity</li>
          </ul>
        </div>
      )
    },
    "growth-solutions": {
      title: "CRM Integration",
      description: (
        <div className="space-y-4">
          <p>Power your business growth with integrated solutions:</p>
          <ul className="list-disc list-inside space-y-2 text-left">
            <li>Streamline operations</li>
            <li>Improve customer relationships</li>
            <li>Boost efficiency</li>
          </ul>
        </div>
      )
    },
    "security-solutions": {
      title: "Tech-Aid Kit",
      description: (
        <div className="space-y-4">
          <p>Keep your systems secure and up-to-date:</p>
          <ul className="list-disc list-inside space-y-2 text-left">
            <li>Regular maintenance</li>
            <li>Security updates</li>
            <li>Performance optimization</li>
          </ul>
        </div>
      )
    },
    "website-refresh": {
      title: "Website Refresh & Revamp",
      description: (
        <div className="space-y-4">
          <p>Transform your digital presence:</p>
          <ul className="list-disc list-inside space-y-2 text-left">
            <li>Modern design principles</li>
            <li>Mobile-first approach</li>
            <li>SEO optimization</li>
          </ul>
        </div>
      )
    },
    "crm-integration": {
      title: "CRM Integration",
      description: (
        <div className="space-y-4">
          <p>Streamline your business operations:</p>
          <ul className="list-disc list-inside space-y-2 text-left">
            <li>Automated workflows</li>
            <li>Customer insights</li>
            <li>Team collaboration</li>
          </ul>
        </div>
      )
    },
    "quote-system": {
      title: "Quote Response System",
      description: (
        <div className="space-y-4">
          <p>Accelerate your quote generation:</p>
          <ul className="list-disc list-inside space-y-2 text-left">
            <li>Quick responses</li>
            <li>Professional templates</li>
            <li>Track conversions</li>
          </ul>
        </div>
      )
    },
    "tech-support": {
      title: "Monthly Tech Support",
      description: (
        <div className="space-y-4">
          <p>Ongoing technical support for your business:</p>
          <ul className="list-disc list-inside space-y-2 text-left">
            <li>Priority assistance</li>
            <li>Regular maintenance</li>
            <li>System optimization</li>
          </ul>
        </div>
      )
    },
  };

  const handleOptionClick = (nextId?: string, action?: () => void) => {
    if (action) {
      action();
      return;
    }
    
    if (nextId) {
      setHistory([...history, currentQuestionId]);
      setCurrentQuestionId(nextId);
    }
  };

  const handleBack = () => {
    if (history.length > 0) {
      const previousQuestion = history[history.length - 1];
      setHistory(history.slice(0, -1));
      setCurrentQuestionId(previousQuestion);
    }
  };

  const currentQuestion = questions[currentQuestionId];
  const isResult = !currentQuestion;
  const result = isResult ? results[currentQuestionId as keyof typeof results] : null;

  return (
    <section className="w-full">
      <div className="w-full max-w-2xl mx-auto">
        <div className="relative rounded-xl bg-card/90 backdrop-blur-sm">
          <Card className="bg-card/90 backdrop-blur-sm shadow-lg border-0 p-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentQuestionId}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                {!isResult ? (
                  <div className="space-y-12">
                    <h3 className="text-4xl font-bold text-center mb-12 text-foreground">
                      {currentQuestion.text}
                    </h3>
                    <div className="grid gap-8">
                      {currentQuestion.options.map((option, index) => (
                        <div
                          key={index}
                          onClick={() => handleOptionClick(option.nextId, option.action)}
                          className="flex items-center gap-6 p-4 rounded-lg cursor-pointer transition-all duration-300 hover:bg-primary/5 group"
                        >
                          {option.icon && (
                            <div className="p-4 rounded-full bg-primary/10 text-primary group-hover:bg-primary group-hover:text-primary-foreground transition-colors duration-300">
                              {option.icon}
                            </div>
                          )}
                          <span className="text-2xl font-medium text-foreground group-hover:text-primary">{option.text}</span>
                          <ArrowRight className="ml-auto h-6 w-6 text-primary opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="text-center space-y-8">
                    <h3 className="text-3xl font-semibold text-foreground">{result?.title}</h3>
                    <div className="text-muted-foreground text-xl">{result?.description}</div>
                    
                    {/* Product Details */}
                    {(() => {
                      const service = services.find(s => s.title === result?.title);
                      if (service) {
                        return (
                          <div className="mt-8 space-y-4 text-left">
                            <h4 className="text-2xl font-semibold">About this Service</h4>
                            <p className="text-lg text-muted-foreground">
                              {service.description}
                            </p>
                            <h4 className="text-2xl font-semibold mt-6">What's Included:</h4>
                            <ul className="list-disc list-inside space-y-2">
                              {service.features.map((feature, index) => (
                                <li key={index} className="text-lg">{feature}</li>
                              ))}
                            </ul>
                            <p className="text-sm text-muted-foreground mt-4">
                              Category: {service.category}
                            </p>
                          </div>
                        );
                      }
                      return null;
                    })()}
                    
                    {/* Contact Form */}
                    <form onSubmit={(e) => handleSubmit(e, result?.title || '')} className="mt-8 space-y-6 text-left">
                      <div className="space-y-4">
                        <div>
                          <Label htmlFor="email">Email</Label>
                          <Input
                            id="email"
                            type="email"
                            value={formData.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            required
                          />
                        </div>
                        <div>
                          <Label htmlFor="name">Full Name</Label>
                          <Input
                            id="name"
                            type="text"
                            value={formData.name}
                            onChange={(e) => handleInputChange('name', e.target.value)}
                            required
                          />
                        </div>
                        <div>
                          <Label htmlFor="companyName">Company Name</Label>
                          <Input
                            id="companyName"
                            type="text"
                            value={formData.companyName}
                            onChange={(e) => handleInputChange('companyName', e.target.value)}
                            required
                          />
                        </div>
                        <div>
                          <Label htmlFor="phoneNumber">Phone Number</Label>
                          <Input
                            id="phoneNumber"
                            type="tel"
                            value={formData.phoneNumber}
                            onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                          />
                        </div>
                        <div>
                          <Label htmlFor="companySize">Company Size</Label>
                          <Select
                            value={formData.companySize}
                            onValueChange={(value) => handleInputChange('companySize', value)}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select company size" />
                            </SelectTrigger>
                            <SelectContent>
                              {COMPANY_SIZES.map((size) => (
                                <SelectItem key={size} value={size}>
                                  {size}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                      
                      <Button
                        type="submit"
                        size="lg"
                        disabled={isLoading}
                        className="w-full bg-primary text-primary-foreground hover:bg-primary/90 text-xl py-8"
                      >
                        {isLoading ? "Submitting..." : "Get Started"}
                        <ArrowRight className="ml-3 h-6 w-6" />
                      </Button>
                    </form>
                  </div>
                )}
              </motion.div>
            </AnimatePresence>

            {(history.length > 0) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <Button
                  variant="ghost"
                  className="mt-8 text-muted-foreground hover:text-foreground text-lg"
                  onClick={handleBack}
                >
                  <ArrowLeft className="mr-3 h-5 w-5" />
                  Back
                </Button>
              </motion.div>
            )}
          </Card>
        </div>
      </div>
    </section>
  );
}
