import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { cn } from "@/lib/utils";

interface DynamicQuoteFormProps {
  settings: {
    formTitle: string;
    primaryColor: string;
    theme: 'light' | 'dark' | 'auto';
    customCss?: string;
    formFields: FormField[];
  };
  onSubmit: (data: any) => Promise<void>;
  className?: string;
}

interface FormField {
  id: string;
  type: 'text' | 'email' | 'tel' | 'textarea' | 'select' | 'number';
  label: string;
  placeholder?: string;
  required?: boolean;
  options?: { label: string; value: string }[];
  validation?: {
    min?: number;
    max?: number;
    pattern?: string;
  };
}

export function DynamicQuoteForm({ settings, onSubmit, className }: DynamicQuoteFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  // Dynamically generate the form schema based on fields
  const generateFormSchema = () => {
    const schemaObject: Record<string, any> = {};
    
    settings.formFields.forEach((field) => {
      let fieldSchema = z.string();
      
      if (field.required) {
        fieldSchema = fieldSchema.min(1, `${field.label} is required`);
      } else {
        fieldSchema = fieldSchema.optional();
      }

      if (field.type === 'email') {
        fieldSchema = z.string().email('Invalid email address');
      }

      if (field.type === 'tel') {
        fieldSchema = z.string().regex(/^\+?[\d\s-()]+$/, 'Invalid phone number');
      }

      if (field.type === 'number') {
        fieldSchema = z.number();
        if (field.validation?.min !== undefined) {
          fieldSchema = fieldSchema.min(field.validation.min);
        }
        if (field.validation?.max !== undefined) {
          fieldSchema = fieldSchema.max(field.validation.max);
        }
      }

      schemaObject[field.id] = fieldSchema;
    });

    return z.object(schemaObject);
  };

  const formSchema = generateFormSchema();
  type FormData = z.infer<typeof formSchema>;

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const handleSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      await onSubmit(data);
      form.reset();
    } catch (error) {
      console.error('Form submission error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Apply custom styles based on settings
  const formStyles = {
    '--primary-color': settings.primaryColor,
  } as React.CSSProperties;

  if (settings.customCss) {
    try {
      const styleElement = document.createElement('style');
      styleElement.textContent = settings.customCss;
      document.head.appendChild(styleElement);
      return () => styleElement.remove();
    } catch (error) {
      console.error('Error applying custom CSS:', error);
    }
  }

  return (
    <div 
      className={cn(
        "w-full max-w-lg mx-auto p-6",
        settings.theme === 'dark' ? 'dark' : '',
        className
      )}
      style={formStyles}
    >
      <h2 className="text-2xl font-bold mb-6 text-center">{settings.formTitle}</h2>
      
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          {settings.formFields.map((field) => (
            <FormField
              key={field.id}
              control={form.control}
              name={field.id}
              render={({ field: formField }) => (
                <FormItem>
                  <FormLabel>{field.label}</FormLabel>
                  <FormControl>
                    {field.type === 'textarea' ? (
                      <Textarea
                        {...formField}
                        placeholder={field.placeholder}
                        className="min-h-[100px]"
                      />
                    ) : field.type === 'select' ? (
                      <Select
                        onValueChange={formField.onChange}
                        defaultValue={formField.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={field.placeholder} />
                        </SelectTrigger>
                        <SelectContent>
                          {field.options?.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    ) : (
                      <Input
                        {...formField}
                        type={field.type}
                        placeholder={field.placeholder}
                      />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}

          <Button
            type="submit"
            className="w-full"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Get Quote"}
          </Button>
        </form>
      </Form>
    </div>
  );
}