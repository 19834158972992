// client/src/components/ServiceSelection/ServiceSelectionStep.tsx
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { ArrowRight } from "lucide-react";

interface ServiceSelectionStepProps {
  selectedServices: string[];
  onUpdate: (services: string[]) => void;
  onNext: () => void;
}

const AVAILABLE_SERVICES = [
  {
    id: "quote-automation",
    name: "Quote Automation System",
    description: "Streamline your quoting process with AI-powered automation.",
  },
  {
    id: "crm-integration",
    name: "CRM Integration",
    description: "Seamlessly connect with your existing CRM system.",
  },
  {
    id: "custom-development",
    name: "Custom Development",
    description: "Tailored solutions for your specific needs.",
  },
  {
    id: "website-refresh",
    name: "Website Refresh & Revamp",
    description: "Modern, responsive website design.",
  },
  {
    id: "database-management",
    name: "Database Management",
    description: "Optimize your data infrastructure.",
  },
  {
    id: "tech-aid",
    name: "Tech-Aid Kit",
    description: "Ongoing technical support and maintenance.",
  },
];

export function ServiceSelectionStep({
  selectedServices,
  onUpdate,
  onNext,
}: ServiceSelectionStepProps) {
  const handleToggleService = (serviceId: string) => {
    if (selectedServices.includes(serviceId)) {
      onUpdate(selectedServices.filter((id) => id !== serviceId));
    } else {
      onUpdate([...selectedServices, serviceId]);
    }
  };

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-2">Select Your Services</h2>
        <p className="text-muted-foreground">
          Choose the services you're interested in. You can select multiple options.
        </p>
      </div>

      <div className="grid gap-4">
        {AVAILABLE_SERVICES.map((service) => (
          <div
            key={service.id}
            className="flex items-start space-x-3 p-4 rounded-lg border border-border/50 hover:border-primary/50 transition-colors"
          >
            <Checkbox
              id={service.id}
              checked={selectedServices.includes(service.id)}
              onCheckedChange={() => handleToggleService(service.id)}
              className="mt-1"
            />
            <div className="space-y-1">
              <Label
                htmlFor={service.id}
                className="text-base font-medium cursor-pointer"
              >
                {service.name}
              </Label>
              <p className="text-sm text-muted-foreground">
                {service.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-end">
        <Button
          onClick={onNext}
          disabled={selectedServices.length === 0}
          className="w-full sm:w-auto"
        >
          Continue
          <ArrowRight className="ml-2 h-5 w-5" />
        </Button>
      </div>
    </div>
  );
}