import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  AlertTriangle,
  RefreshCcw,
  Home,
  ArrowLeft,
  Network,
  Database,
  Lock,
  Copy,
  Files,
  HardDrive,
} from 'lucide-react';
import { useToast } from '@/hooks/use-toast';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(error: Error): State {
    console.error('ErrorBoundary caught an error:', error);
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error);
    console.error('Component stack:', errorInfo.componentStack);
    
    // Log additional context information
    console.debug('Error occurred at:', new Date().toISOString());
    console.debug('URL:', window.location.href);
    console.debug('User Agent:', navigator.userAgent);
    console.debug('Viewport:', {
      width: window.innerWidth,
      height: window.innerHeight,
    });
    
    this.setState({ errorInfo });
    this.logErrorToService(error, errorInfo);
  }

  private logErrorToService(error: Error, errorInfo: ErrorInfo) {
    // Structure error data
    const errorLog = {
      timestamp: new Date().toISOString(),
      error: {
        name: error.name,
        message: error.message,
        stack: error.stack,
      },
      componentStack: errorInfo.componentStack,
      location: window.location.href,
      userAgent: navigator.userAgent,
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      localStorage: this.safelyCheckLocalStorage(),
    };
    
    console.debug('Structured error log:', errorLog);
  }

  private safelyCheckLocalStorage(): { available: boolean; size?: string } {
    try {
      const total = JSON.stringify(localStorage).length;
      return { available: true, size: `${(total / 1024).toFixed(2)}KB` };
    } catch {
      return { available: false };
    }
  }

  private getErrorType(): { icon: ReactNode; title: string; steps: string[] } {
    const { error } = this.state;
    const message = error?.message.toLowerCase() || '';

    if (message.includes('network') || message.includes('fetch') || message.includes('api')) {
      return {
        icon: <Network className="h-12 w-12 text-destructive" />,
        title: 'Network Error',
        steps: [
          'Check your internet connection',
          'Verify the API endpoint is accessible',
          'Clear your browser cache',
          'Try disabling any VPN or proxy services',
        ],
      };
    }

    if (message.includes('auth') || message.includes('login') || message.includes('permission') || message.includes('unauthorized')) {
      return {
        icon: <Lock className="h-12 w-12 text-destructive" />,
        title: 'Authentication Error',
        steps: [
          'Try logging out and back in',
          'Clear your browser cookies',
          'Verify you have the necessary permissions',
          'Check if your session has expired',
          'Contact support if the issue persists',
        ],
      };
    }

    if (message.includes('database') || message.includes('sql')) {
      return {
        icon: <Database className="h-12 w-12 text-destructive" />,
        title: 'Database Error',
        steps: [
          'Refresh the page and try again',
          'Verify your data inputs are correct',
          'Clear your local storage',
          'Contact support if the issue persists',
        ],
      };
    }

    if (message.includes('memory') || message.includes('storage') || message.includes('quota')) {
      return {
        icon: <HardDrive className="h-12 w-12 text-destructive" />,
        title: 'Storage Error',
        steps: [
          'Clear your browser cache',
          'Remove unnecessary browser data',
          'Try using incognito mode',
          'Contact support if the issue persists',
        ],
      };
    }

    if (message.includes('file') || message.includes('upload') || message.includes('download')) {
      return {
        icon: <Files className="h-12 w-12 text-destructive" />,
        title: 'File Operation Error',
        steps: [
          'Check your file size and format',
          'Ensure you have a stable connection',
          'Try with a different file',
          'Contact support if the issue persists',
        ],
      };
    }

    return {
      icon: <AlertTriangle className="h-12 w-12 text-destructive" />,
      title: 'Unexpected Error',
      steps: [
        'Refresh the page',
        'Clear your browser cache',
        'Try using a different browser',
        'Contact support if the issue persists',
      ],
    };
  }

  private copyErrorDetails = () => {
    const { error, errorInfo } = this.state;
    const errorDetails = {
      timestamp: new Date().toISOString(),
      error: {
        message: error?.message,
        stack: error?.stack,
      },
      componentStack: errorInfo?.componentStack,
      url: window.location.href,
    };

    navigator.clipboard.writeText(JSON.stringify(errorDetails, null, 2))
      .then(() => {
        const toast = useToast();
        toast.toast({
          title: "Success",
          description: "Error details copied to clipboard",
        });
      })
      .catch(console.error);
  };

  public render() {
    if (this.state.hasError) {
      const { icon, title, steps } = this.getErrorType();

      return (
        <div className="min-h-screen flex items-center justify-center bg-background p-4" role="alert" aria-live="assertive">
          <Card className="max-w-lg w-full p-6 space-y-6">
            <div className="flex flex-col items-center gap-4 text-center">
              {icon}
              <h1 className="text-2xl font-bold text-foreground">{title}</h1>
              <p className="text-muted-foreground">
                {this.state.error?.message || 'An unexpected error occurred'}
              </p>
            </div>

            <div className="space-y-4">
              <h2 className="font-semibold text-lg">Troubleshooting Steps:</h2>
              <ul className="space-y-2" role="list">
                {steps.map((step, index) => (
                  <li key={index} className="flex items-center gap-2 text-muted-foreground">
                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border text-xs" aria-hidden="true">
                      {index + 1}
                    </span>
                    {step}
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col sm:flex-row gap-2 pt-4">
              <Button
                variant="outline"
                className="flex-1"
                onClick={() => window.history.back()}
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                Go Back
              </Button>
              <Button
                variant="outline"
                className="flex-1"
                onClick={() => window.location.reload()}
              >
                <RefreshCcw className="mr-2 h-4 w-4" />
                Refresh Page
              </Button>
              <Button
                variant="default"
                className="flex-1"
                onClick={() => window.location.href = '/'}
              >
                <Home className="mr-2 h-4 w-4" />
                Home
              </Button>
            </div>

            {process.env.NODE_ENV === 'development' && this.state.errorInfo && (
              <div className="mt-6 space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="font-semibold">Debug Information</h3>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={this.copyErrorDetails}
                    className="flex items-center gap-2"
                  >
                    <Copy className="h-4 w-4" />
                    Copy Details
                  </Button>
                </div>
                <div className="p-4 bg-muted rounded-lg overflow-auto">
                  <p className="font-mono text-xs text-muted-foreground whitespace-pre-wrap">
                    {this.state.errorInfo.componentStack}
                  </p>
                </div>
              </div>
            )}
          </Card>
        </div>
      );
    }

    return this.props.children;
  }
}
