// client/src/components/ServiceSelection/MessageStep.tsx
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { ArrowLeft, ArrowRight } from "lucide-react";

interface MessageStepProps {
  message: string;
  onUpdate: (message: string) => void;
  onNext: () => void;
  onBack: () => void;
}

export function MessageStep({
  message,
  onUpdate,
  onNext,
  onBack,
}: MessageStepProps) {
  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-2">Additional Requirements</h2>
        <p className="text-muted-foreground">
          Tell us about any specific requirements or questions you have. This helps us provide a more accurate quote.
        </p>
      </div>

      <div className="space-y-4">
        <div>
          <Label htmlFor="message">Message</Label>
          <Textarea
            id="message"
            placeholder="E.g., Project timeline, specific features needed, or any other requirements..."
            value={message}
            onChange={(e) => onUpdate(e.target.value)}
            className="h-32"
          />
        </div>
      </div>

      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>
          <ArrowLeft className="mr-2 h-5 w-5" />
          Back
        </Button>
        <Button onClick={onNext}>
          Continue
          <ArrowRight className="ml-2 h-5 w-5" />
        </Button>
      </div>
    </div>
  );
}