import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useCompanyProfile } from "../hooks/use-company-profile";
import { Loader2 } from "lucide-react";
import { useEffect } from "react";

const formSchema = z.object({
  clientName: z.string().min(2, "Name must be at least 2 characters").optional(),
  clientEmail: z.string().email("Invalid email address").optional(),
}).refine(
  (data) => {
    if (data.clientName || data.clientEmail) {
      return data.clientName && data.clientEmail;
    }
    return true;
  },
  {
    message: "Both client name and email must be provided together",
  }
);

type FormValues = z.infer<typeof formSchema>;

interface ProposalFormProps {
  defaultValues: Partial<FormValues>;
  onSubmit: (data: FormValues) => void;
}

export function ProposalForm({ defaultValues, onSubmit }: ProposalFormProps) {
  const { profile, isLoading: profileLoading } = useCompanyProfile();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      clientName: defaultValues.clientName ?? "",
      clientEmail: defaultValues.clientEmail ?? "",
    },
    mode: "onChange",
  });

  // Update form when defaultValues change
  useEffect(() => {
    if (defaultValues) {
      const newValues = {
        clientName: defaultValues.clientName ?? "",
        clientEmail: defaultValues.clientEmail ?? "",
      };
      
      // Only update if values have changed
      const currentValues = form.getValues();
      if (
        currentValues.clientName !== newValues.clientName ||
        currentValues.clientEmail !== newValues.clientEmail
      ) {
        form.reset(newValues);
      }
    }
  }, [defaultValues, form]);

  if (profileLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!profile) {
    return (
      <Alert>
        <AlertDescription>
          Please complete your company profile before creating proposals.
        </AlertDescription>
      </Alert>
    );
  }

  const handleSubmit = (data: FormValues) => {
    onSubmit(data);
  };

  return (
    <Card className="p-6">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="space-y-4"
          noValidate
        >
          <FormField
            control={form.control}
            name="clientName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Client Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Enter client name"
                    value={field.value ?? ""}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="clientEmail"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Client Email</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="email"
                    placeholder="Enter client email"
                    value={field.value ?? ""}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            disabled={!form.formState.isValid || form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : null}
            Continue
          </Button>
        </form>
      </Form>
    </Card>
  );
}
