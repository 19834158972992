import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Switch, Route } from "wouter";
import "./index.css";
import { SWRConfig } from "swr";
import { fetcher } from "./lib/fetcher";
import { Toaster } from "@/components/ui";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import CreateProposal from "./pages/CreateProposal";
import SignupPage from "./pages/SignupPage";
import WaitlistThankYou from "./pages/WaitlistThankYou";
import LoginPage from "./pages/LoginPage";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { useLocation } from "wouter";
import BookCall from "@/pages/BookCall";
import { ThemeProvider } from './contexts/ThemeContext';
import PaymentPage from "@/pages/PaymentPage";
import ProductsPage from "@/pages/ProductsPage";
import { ContactPage } from "@/pages/ContactPage";
import FutureProjects from "@/pages/FutureProjects";

console.log('Mounting app...');

const rootElement = document.getElementById("root");
if (!rootElement) {
  console.error("Root element not found");
  throw new Error("Root element not found - critical mounting error");
}

createRoot(rootElement).render(
  <StrictMode>
    <ThemeProvider>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <SWRConfig value={{ fetcher }}>
            <Switch>
              <Route path="/" component={LandingPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/waitlist-thank-you" component={WaitlistThankYou} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/create-proposal" component={CreateProposal} />
              <Route path="/book-call" component={BookCall} />
              <Route path="/payment" component={PaymentPage} />
              <Route path="/products" component={ProductsPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/future-projects" component={FutureProjects} />
              <Route>404 Page Not Found</Route>
            </Switch>
            <Toaster />
          </SWRConfig>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  </StrictMode>
);
