import { useState } from "react";
import { Header } from "@/components/Header";
import { Button } from "@/components/ui/button";
import { useLocation } from "wouter";
import { ArrowRight } from "lucide-react";
import { ServiceOfferings } from "@/components/ServiceOfferings";

interface Service {
  id: number;
  title: string;
  description: string;
  features: string[];
  category: string;
}

const services: Service[] = [
  {
    id: 1,
    title: "Quote Automation System",
    description: "Streamline your quoting process with AI-powered automation, enabling faster, more accurate client responses.",
    features: [
      "Automated quote generation in under 2 minutes",
      "Website integration for seamless use",
      "Custom service templates tailored to your business",
      "Real-time quote tracking dashboard",
      "Detailed analytics to measure success",
      "Mobile-friendly client portal"
    ],
    category: "Automation"
  },
  {
    id: 2,
    title: "Website Refresh & Revamp",
    description: "Transform your digital presence with our comprehensive website modernization services.",
    features: [
      "Modern design refresh",
      "Mobile responsiveness",
      "Lead capture optimization",
      "Professional branding",
      "SEO optimization",
      "Performance tuning"
    ],
    category: "Web Development"
  },
  {
    id: 3,
    title: "CRM Integration",
    description: "Streamline your customer relationships with powerful CRM solutions, ready for future proposal management features.",
    features: [
      "Popular CRM integrations",
      "Data migration",
      "Workflow automation",
      "Team training",
      "Custom reporting",
      "Future proposal management integration"
    ],
    category: "Business Solutions"
  },
  {
    id: 4,
    title: "Database Management",
    description: "Optimize your data infrastructure for better performance and scalability.",
    features: [
      "Setup & optimization",
      "Ongoing maintenance",
      "Performance monitoring",
      "Scalability planning",
      "Security hardening",
      "Backup solutions"
    ],
    category: "Infrastructure"
  },
  {
    id: 5,
    title: "Tech-Aid Kit",
    description: "Your monthly technical support solution for ongoing maintenance and updates.",
    features: [
      "Regular updates",
      "Bug fixes",
      "Performance monitoring",
      "Priority support",
      "Security patches",
      "Monthly reports"
    ],
    category: "Support"
  },
  {
    id: 6,
    title: "Custom Development",
    description: "Tailored software solutions designed for your specific business needs, with upcoming contract management capabilities.",
    features: [
      "Custom tools",
      "Rapid development",
      "Focused solutions",
      "Ongoing support",
      "Contract management ready",
      "Future-proof design"
    ],
    category: "Development"
  }
];

export default function ProductsPage() {
  const [, navigate] = useLocation();

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="py-24">
        <div className="container mx-auto px-8">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Our Services</h1>
            <p className="text-xl text-muted-foreground">
              Comprehensive solutions to modernize and grow your business
            </p>
          </div>

          {/* Services Grid */}
          <div className="mb-24">
            <ServiceOfferings showAll={true} />
          </div>

          {/* CTA Section */}
          <div className="mt-16 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Business?</h2>
            <p className="text-xl text-muted-foreground mb-8">
              Book a free consultation to discuss your needs and explore solutions
            </p>
            <Button 
              size="lg" 
              onClick={() => navigate('/book-call')} 
              className="text-lg px-8 bg-primary text-primary-foreground hover:bg-primary/90"
            >
              Book Free Consultation
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}