import { useState, useEffect } from "react";
import { useLocation } from "wouter";
import { ProposalSteps } from "../components/ProposalSteps";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { ArrowLeft, ArrowRight, Save, Loader2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { useProposal } from "../hooks/use-proposal";
import type { Proposal } from "../../../server/db/schema";

interface FormData {
  clientName: string;
  clientEmail: string;
  projectDetails: string;
  pricingSheetId: number | null;
  totalPrice: number;
  taxRate: number;
  customMessage: string;
  status: "draft" | "complete";
}

const defaultFormData: FormData = {
  clientName: "",
  clientEmail: "",
  projectDetails: "",
  pricingSheetId: null,
  totalPrice: 0,
  taxRate: 0,
  customMessage: "",
  status: "draft",
};

function transformDraftToFormData(draft: Proposal): FormData {
  console.log('Transforming draft data:', {
    id: draft.id,
    status: draft.status,
    timestamp: new Date().toISOString()
  });

  const formData = {
    clientName: draft.clientName ?? "",
    clientEmail: draft.clientEmail ?? "",
    projectDetails: draft.projectDetails ?? "",
    pricingSheetId: draft.pricingSheetId ?? null,
    totalPrice: draft.totalPrice ?? 0,
    taxRate: draft.taxRate ?? 0,
    customMessage: draft.customMessage ?? "",
    status: draft.status as "draft" | "complete",
  };

  console.log('Transformed form data:', {
    ...formData,
    timestamp: new Date().toISOString()
  });

  return formData;
}

function determineInitialStep(data: FormData): number {
  if (!data.clientName || !data.clientEmail) return 1;
  if (!data.projectDetails?.trim()) return 2;
  if (!data.pricingSheetId || data.totalPrice <= 0) return 3;
  if (!data.customMessage?.trim()) return 4;
  return 5;
}

export default function CreateProposal() {
  const [, navigate] = useLocation();
  const { toast } = useToast();
  const { create, update } = useProposal();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [isStepValid, setIsStepValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);

  const [location] = useLocation();
  const draftId = location.includes('#draft=') 
    ? parseInt(location.split('#draft=')[1]) 
    : null;

  useEffect(() => {
    const loadDraft = async () => {
      if (!draftId) {
        console.log('No draft ID in URL, skipping draft load');
        setIsLoadingDraft(false);
        return;
      }

      try {
        console.log('Loading draft proposal:', {
          draftId,
          timestamp: new Date().toISOString()
        });

        const response = await fetch(`/api/proposals/${draftId}`);
        if (!response.ok) {
          throw new Error('Failed to load draft proposal');
        }

        const result = await response.json();
        
        console.log('Draft data received:', {
          data: result.data,
          timestamp: new Date().toISOString()
        });

        if (!result.data) {
          throw new Error('No data received from server');
        }

        const transformedData = transformDraftToFormData(result.data);
        console.log('Transformed form data:', {
          data: transformedData,
          timestamp: new Date().toISOString()
        });

        setFormData(transformedData);
        setStep(determineInitialStep(transformedData));
        setIsStepValid(validateCurrentStep(transformedData));

      } catch (error) {
        console.error('Error loading draft:', {
          error: error instanceof Error ? error.message : 'Unknown error',
          draftId,
          timestamp: new Date().toISOString()
        });
        setLoadError(error instanceof Error ? error.message : 'Failed to load draft');
        toast({
          title: "Error",
          description: error instanceof Error ? error.message : 'Failed to load draft proposal',
          variant: "destructive",
        });
      } finally {
        setIsLoadingDraft(false);
      }
    };

    loadDraft();
  }, [draftId, toast]);

  const validateCurrentStep = (data: FormData = formData) => {
    const validation = {
      1: () => Boolean(data.clientName?.trim() && data.clientEmail?.trim()),
      2: () => Boolean(data.projectDetails?.trim()),
      3: () => Boolean(data.pricingSheetId && data.totalPrice > 0),
      4: () => Boolean(data.customMessage?.trim()),
      5: () => true,
    };

    const isValid = validation[step as keyof typeof validation]?.() ?? false;
    return isValid;
  };

  useEffect(() => {
    const isValid = validateCurrentStep();
    setIsStepValid(isValid);
  }, [step, formData]);

  const handleStepSubmit = async (stepData: Partial<FormData>) => {
    const updatedData = {
      ...formData,
      ...stepData
    };
    
    const isValid = validateCurrentStep(updatedData);
    if (!isValid) {
      toast({
        title: "Validation Error",
        description: "Please fill in all required fields for this step",
        variant: "destructive",
      });
      return;
    }

    setFormData(updatedData);

    // Auto-save draft after each step
    try {
      if (draftId) {
        await update(draftId, updatedData);
      } else {
        const result = await create(updatedData);
        setFormData(prevData => ({
          ...prevData,
          ...result.data
        }));
      }
    } catch (error) {
      console.error('Error saving draft:', {
        error,
        draftId,
        formData: updatedData,
        timestamp: new Date().toISOString()
      });
      // Continue with step navigation even if auto-save fails
    }

    if (step === 5) {
      try {
        const completeData = {
          ...updatedData,
          status: "complete" as const
        };
        
        if (draftId) {
          await update(draftId, completeData);
        } else {
          await create(completeData);
        }
        toast({
          title: "Success!",
          description: "Proposal created successfully",
        });
        navigate("/dashboard");
      } catch (error: any) {
        console.error('Error creating complete proposal:', {
          error: error.message,
          formData: updatedData,
          timestamp: new Date().toISOString(),
        });
        toast({
          title: "Error",
          description: error.message || "Failed to create proposal",
          variant: "destructive",
        });
      }
      return;
    }

    setStep((prev) => prev + 1);
  };

  const handleSaveAsDraft = async () => {
    setIsSaving(true);
    try {
      const draftData = {
        ...formData,
        status: "draft" as const
      };

      if (draftId) {
        await update(draftId, draftData);
      } else {
        const result = await create(draftData);
        setFormData(prevData => ({
          ...prevData,
          ...result.data
        }));
      }
      
      toast({
        title: "Success!",
        description: "Proposal saved as draft",
      });
      navigate("/dashboard");
    } catch (error: any) {
      console.error('Error saving draft:', {
        error: error.message,
        formData,
        timestamp: new Date().toISOString(),
      });
      toast({
        title: "Error",
        description: error.message || "Failed to save draft",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handlePrevStep = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };

  if (isLoadingDraft) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (loadError) {
    return (
      <div className="container mx-auto p-8 max-w-3xl">
        <div className="flex flex-col items-center justify-center space-y-4">
          <h2 className="text-xl font-semibold text-destructive">{loadError}</h2>
          <Button onClick={() => navigate("/dashboard")}>
            Return to Dashboard
          </Button>
        </div>
      </div>
    );
  }

  const progress = (step / 5) * 100;

  return (
    <div className="container mx-auto p-8 max-w-3xl">
      <div className="mb-8">
        <Button
          variant="ghost"
          onClick={() => navigate("/dashboard")}
          className="mb-4"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Dashboard
        </Button>
        <h1 className="text-3xl font-bold">{draftId ? 'Resume Draft Proposal' : 'Create New Proposal'}</h1>
        <Progress value={progress} className="mt-4" />
      </div>

      <div className="space-y-8">
        <ProposalSteps
          currentStep={step}
          formData={formData}
          onSubmit={handleStepSubmit}
        />

        <div className="flex justify-between mt-6">
          <div className="flex gap-2">
            {step > 1 && (
              <Button variant="outline" onClick={handlePrevStep}>
                <ArrowLeft className="mr-2 h-4 w-4" />
                Previous Step
              </Button>
            )}
            <Button 
              variant="outline" 
              onClick={handleSaveAsDraft}
              disabled={isSaving}
            >
              {isSaving ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Save className="mr-2 h-4 w-4" />
              )}
              Save as Draft
            </Button>
          </div>
          {step < 5 && (
            <Button
              className="ml-auto"
              disabled={!isStepValid}
              onClick={() => handleStepSubmit({})}
            >
              Next Step
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
