import { ProposalForm } from "./ProposalForm";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { usePricingSheets } from "../hooks/use-pricing";
import { useState, useEffect, useCallback } from "react";

interface FormData {
  clientName: string;
  clientEmail: string;
  projectDetails: string;
  pricingSheetId: number;
  totalPrice: number;
  taxRate: number;
  customMessage: string;
  status: "draft" | "complete";
}

interface ProposalStepsProps {
  currentStep: number;
  formData: FormData;
  onSubmit: (data: Partial<FormData>) => void;
}

export function ProposalSteps({ currentStep, formData, onSubmit }: ProposalStepsProps) {
  const { sheets } = usePricingSheets();
  const [selectedPrice, setSelectedPrice] = useState<number>(formData.totalPrice || 0);
  const [taxRate, setTaxRate] = useState<number>(formData.taxRate || 0);
  const [calculatedTotal, setCalculatedTotal] = useState<number>(0);
  const [localFormData, setLocalFormData] = useState<FormData>({
    clientName: formData.clientName || "",
    clientEmail: formData.clientEmail || "",
    projectDetails: formData.projectDetails || "",
    pricingSheetId: formData.pricingSheetId || 0,
    totalPrice: formData.totalPrice || 0,
    taxRate: formData.taxRate || 0,
    customMessage: formData.customMessage || "",
    status: formData.status || "draft",
  });

  const calculateTotal = useCallback((price: number, tax: number) => {
    return price * (1 + tax / 100);
  }, []);

  // Update calculated total when price or tax rate changes
  useEffect(() => {
    if (selectedPrice || taxRate) {
      const total = calculateTotal(selectedPrice, taxRate);
      setCalculatedTotal(Number(total.toFixed(2)));
    }
  }, [selectedPrice, taxRate, calculateTotal]);

  // Sync local state with parent form data
  useEffect(() => {
    setLocalFormData({
      clientName: formData.clientName || "",
      clientEmail: formData.clientEmail || "",
      projectDetails: formData.projectDetails || "",
      pricingSheetId: formData.pricingSheetId || 0,
      totalPrice: formData.totalPrice || 0,
      taxRate: formData.taxRate || 0,
      customMessage: formData.customMessage || "",
      status: formData.status || "draft",
    });
    setSelectedPrice(formData.totalPrice || 0);
    setTaxRate(formData.taxRate || 0);
    
    // Update calculated total on form data sync
    if (formData.totalPrice || formData.taxRate) {
      const total = calculateTotal(formData.totalPrice || 0, formData.taxRate || 0);
      setCalculatedTotal(Number(total.toFixed(2)));
    }
  }, [formData, currentStep, calculateTotal]);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const handleFormDataChange = (data: Partial<FormData>) => {
    const updatedData = { ...localFormData, ...data };
    setLocalFormData(updatedData);
    
    console.log('Form data changed:', {
      currentStep,
      data: updatedData,
      timestamp: new Date().toISOString()
    });
  };

  const handleStepSubmit = () => {
    console.log('Submitting step:', {
      step: currentStep,
      data: localFormData,
      timestamp: new Date().toISOString()
    });
    onSubmit(localFormData);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ProposalForm
            key={`client-info-${formData.clientName}-${formData.clientEmail}`}
            defaultValues={{
              clientName: formData.clientName || "",
              clientEmail: formData.clientEmail || "",
            }}
            onSubmit={onSubmit}
          />
        );

      case 2:
        return (
          <Card className="p-6 space-y-4">
            <h2 className="text-xl font-semibold">Project Details</h2>
            <p className="text-sm text-muted-foreground">
              Provide detailed information about the project scope and requirements.
            </p>
            <Textarea
              placeholder="Describe the project scope and requirements..."
              className="min-h-[200px]"
              value={localFormData.projectDetails || ""}
              onChange={(e) => handleFormDataChange({ projectDetails: e.target.value })}
            />
            <Button 
              className="w-full"
              onClick={handleStepSubmit}
              disabled={!localFormData.projectDetails?.trim()}
            >
              Continue
            </Button>
          </Card>
        );

      case 3:
        return (
          <Card className="p-6 space-y-4">
            <h2 className="text-xl font-semibold">Pricing Details</h2>
            <p className="text-sm text-muted-foreground">
              Select a pricing sheet and adjust the pricing details as needed.
            </p>
            <div className="space-y-4">
              <Select
                value={localFormData.pricingSheetId?.toString()}
                onValueChange={(value) => {
                  const sheetId = parseInt(value);
                  const sheet = sheets?.find((s) => s.id === sheetId);
                  if (sheet?.content) {
                    const basePrice = Number(sheet.content.basePrice) || 0;
                    setSelectedPrice(basePrice);
                    const total = calculateTotal(basePrice, taxRate);
                    handleFormDataChange({
                      pricingSheetId: sheetId,
                      totalPrice: total,
                    });
                  }
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select pricing sheet" />
                </SelectTrigger>
                <SelectContent>
                  {sheets?.map((sheet) => (
                    <SelectItem key={sheet.id} value={sheet.id.toString()}>
                      {sheet.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <div className="space-y-2">
                <label className="text-sm font-medium">Base Price</label>
                <Input
                  type="number"
                  min="0"
                  step="0.01"
                  value={selectedPrice}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setSelectedPrice(value);
                      const total = calculateTotal(value, taxRate);
                      handleFormDataChange({ 
                        totalPrice: total 
                      });
                    }
                  }}
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Tax Rate (%)</label>
                <Input
                  type="number"
                  min="0"
                  max="100"
                  value={taxRate}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setTaxRate(value);
                      const total = calculateTotal(selectedPrice, value);
                      handleFormDataChange({ 
                        taxRate: value,
                        totalPrice: total
                      });
                    }
                  }}
                />
              </div>

              <div className="pt-4 border-t">
                <p className="text-lg font-semibold">
                  Total: {formatCurrency(calculatedTotal)}
                </p>
              </div>

              <Button 
                className="w-full"
                onClick={handleStepSubmit}
                disabled={!localFormData.pricingSheetId || calculatedTotal <= 0}
              >
                Continue
              </Button>
            </div>
          </Card>
        );

      case 4:
        return (
          <Card className="p-6 space-y-4">
            <h2 className="text-xl font-semibold">Custom Message</h2>
            <p className="text-sm text-muted-foreground">
              Add a personal message to customize your proposal for the client.
            </p>
            <Textarea
              placeholder="Add a personal message to your client..."
              className="min-h-[200px]"
              value={localFormData.customMessage || ""}
              onChange={(e) => handleFormDataChange({ customMessage: e.target.value })}
            />
            <Button 
              className="w-full"
              onClick={handleStepSubmit}
              disabled={!localFormData.customMessage?.trim()}
            >
              Continue
            </Button>
          </Card>
        );

      case 5:
        return (
          <Card className="p-6 space-y-6">
            <h2 className="text-xl font-semibold">Preview Proposal</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium">Client Information</h3>
                <p>Name: {formData.clientName}</p>
                <p>Email: {formData.clientEmail}</p>
              </div>
              
              <div>
                <h3 className="font-medium">Project Details</h3>
                <p className="whitespace-pre-wrap">{formData.projectDetails}</p>
              </div>
              
              <div>
                <h3 className="font-medium">Pricing Details</h3>
                <p>Base Price: {formatCurrency(selectedPrice)}</p>
                <p>Tax Rate: {taxRate}%</p>
                <p className="font-semibold">Total: {formatCurrency(calculatedTotal)}</p>
              </div>
              
              <div>
                <h3 className="font-medium">Custom Message</h3>
                <p className="whitespace-pre-wrap">{formData.customMessage}</p>
              </div>

              <Button 
                className="w-full"
                onClick={handleStepSubmit}
              >
                Create Proposal
              </Button>
            </div>
          </Card>
        );

      default:
        return null;
    }
  };

  return <div className="space-y-4">{renderStep()}</div>;
}