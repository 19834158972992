import useSWR from "swr";
import type { ProposalRevision } from "../../../server/db/schema";

interface ProposalRevisionsResponse {
  status: "success" | "error";
  data: ProposalRevision[];
  message?: string;
}

export function useProposalRevisions(proposalId?: number) {
  const { data: response, error } = useSWR<ProposalRevisionsResponse>(
    proposalId ? `/api/proposals/${proposalId}/revisions` : null
  );

  return {
    revisions: response?.data || [],
    isLoading: !error && !response,
    error,
  };
}
