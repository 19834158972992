// client/src/components/ServiceSelection/ServiceSelectionFlow.tsx
import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { ServiceSelectionStep } from "./ServiceSelectionStep";
import { ContactDetailsStep } from "./ContactDetailsStep";
import { MessageStep } from "./MessageStep";
import { ConfirmationStep } from "./ConfirmationStep";

interface ServiceSelectionData {
  selectedServices: string[];
  contactDetails: {
    email: string;
    name: string;
    companyName: string;
    phoneNumber?: string;
    city?: string;
    state?: string;
    country: string;
    otherCountry?: string;
    companySize?: string;
  };
  message: string;
}

export function ServiceSelectionFlow() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<ServiceSelectionData>({
    selectedServices: [],
    contactDetails: {
      email: "",
      name: "",
      companyName: "",
      phoneNumber: "",
      city: "",
      state: "",
      country: "United States",
      otherCountry: "",
      companySize: "",
    },
    message: "",
  });

  const updateFormData = (data: Partial<ServiceSelectionData>) => {
    setFormData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = async () => {
    // TODO: Submit to backend/Zapier
    // Will need API endpoint details
  };

  return (
    <section className="py-24 bg-gradient-to-b from-background via-secondary/20 to-background">
      <div className="container mx-auto px-8">
        <div className="max-w-3xl mx-auto">
          <div className="mb-8">
            <Progress value={(currentStep / 4) * 100} className="h-2" />
          </div>

          <Card className="p-6 md:p-8">
            {currentStep === 1 && (
              <ServiceSelectionStep
                selectedServices={formData.selectedServices}
                onUpdate={(services) =>
                  updateFormData({ selectedServices: services })
                }
                onNext={handleNext}
              />
            )}
            {currentStep === 2 && (
              <ContactDetailsStep
                contactDetails={formData.contactDetails}
                selectedServices={formData.selectedServices}
                onUpdate={(details) =>
                  updateFormData({ contactDetails: details })
                }
                onNext={handleNext}
                onBack={handleBack}
              />
            )}
            {currentStep === 3 && (
              <MessageStep
                message={formData.message}
                onUpdate={(message) => updateFormData({ message })}
                onNext={handleNext}
                onBack={handleBack}
              />
            )}
            {currentStep === 4 && (
              <ConfirmationStep
                formData={formData}
                onBack={handleBack}
                calendarUrl="YOUR_CAL_COM_URL"
              />
            )}
          </Card>
        </div>
      </div>
    </section>
  );
}