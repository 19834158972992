import { Card } from "@/components/ui/card";
import { Header } from "@/components/Header";
import { Check } from "lucide-react";

export default function WaitlistThankYou() {
  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <Card className="max-w-2xl mx-auto p-8 text-center">
          <div className="mb-6">
            <div className="mx-auto w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
              <Check className="w-6 h-6 text-green-600" />
            </div>
          </div>
          <h1 className="text-3xl font-bold mb-4">Thank You for Joining Our Waitlist!</h1>
          <p className="text-muted-foreground mb-6">
            We're excited to have you on board. We'll notify you as soon as we're ready to welcome you to ProposalStream.
          </p>
          <p className="text-sm text-muted-foreground">
            In the meantime, feel free to follow us on social media for updates.
          </p>
        </Card>
      </div>
    </div>
  );
}