import useSWR from "swr";
import type { CompanyProfile } from "../../../server/db/schema";

interface CompanyProfileResponse {
  status: "success" | "error";
  data?: CompanyProfile;
  message?: string;
}

export function useCompanyProfile() {
  const { data: response, error, mutate } = useSWR<CompanyProfileResponse>(
    "/api/company-profile"
  );

  return {
    profile: response?.data,
    isLoading: !error && !response,
    error,
    async update(data: Partial<CompanyProfile>) {
      try {
        const response = await fetch("/api/company-profile", {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Failed to update company profile");
        }

        const result = await response.json();
        await mutate(result);
        return result;
      } catch (error: any) {
        console.error("Error updating company profile:", error);
        throw error;
      }
    },
  };
}
