import { useState } from "react";
import { useLocation, Link } from "wouter";
import { Button } from "@/components/ui/button";
import LogoLong from "@/assets/logos/LogoLong.png";
import { useTheme } from '../contexts/ThemeContext';
import { Sun, Moon, ChevronDown } from "lucide-react";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu";

function ThemeToggle() {
  const { isDark, toggleTheme } = useTheme();
  
  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={toggleTheme}
      className="w-9 h-9 rounded-full hover:bg-primary/10"
    >
      {isDark ? (
        <Sun className="h-5 w-5 text-primary" />
      ) : (
        <Moon className="h-5 w-5 text-primary" />
      )}
    </Button>
  );
}

export function Header() {
  const [, navigate] = useLocation();
  
  const handleLogoClick = () => {
    navigate('/');
    window.gtag('event', 'logo_click', {
      event_category: 'Navigation',
      event_label: 'Logo'
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const handleNavigation = (path: string) => {
    window.gtag('event', 'page_view', {
      page_path: path
    });
    navigate(path);
  };
  
  return (
    <header className="w-full bg-background/80 border-b border-border/50 backdrop-blur-sm sticky top-0 z-50 mb-12">
      <div className="container mx-auto px-8">
        <div className="flex items-center justify-between h-16">
          <div 
            className="flex-shrink-0 cursor-pointer transition-opacity hover:opacity-80" 
            onClick={handleLogoClick}
          >
            <img 
              src={LogoLong}
              alt="ProposalStream" 
              className="h-8"
            />
          </div>

          <nav className="hidden md:flex items-center space-x-8">
            <Link href="/">
              <a className="text-foreground/70 hover:text-foreground transition-colors duration-200 text-sm no-underline">Home</a>
            </Link>
            <a 
              href="/#services" 
              className="text-foreground/70 hover:text-foreground transition-colors duration-200 text-sm no-underline cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                if (window.location.pathname === '/') {
                  document.querySelector('#services')?.scrollIntoView({ behavior: 'smooth' });
                } else {
                  navigate('/?scrollTo=services');
                }
              }}
            >
              Services
            </a>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="link" className="text-foreground/70 hover:text-foreground transition-colors duration-200 p-0 h-auto font-normal text-sm">
                  Resources
                  <ChevronDown className="ml-1 h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" className="bg-card border border-border/50">
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/future-projects">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Future Projects</a>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/contact">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Contact</a>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </nav>

          <div className="flex items-center space-x-3">
            <ThemeToggle />
            <Button
              variant="ghost"
              className="text-foreground/70 hover:text-foreground hover:bg-primary/10 transition-colors duration-200"
              onClick={() => handleNavigation("/login")}
            >
              Login
            </Button>
            <Button
              variant="default"
              className="bg-primary text-primary-foreground hover:bg-primary/90 transition-colors duration-200"
              onClick={() => handleNavigation("/signup")}
            >
              Sign Up
            </Button>
            <Button
              variant="outline"
              className="border-primary text-primary hover:bg-primary/10 transition-colors duration-200"
              onClick={() => handleNavigation("/book-call")}
            >
              Book Call
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
}
