import { useState, useEffect } from "react";
import { Button, Input, Card } from "@/components/ui";
import { useToast } from "@/hooks/use-toast";
import { GoogleSignInButton } from "@/components/GoogleSignIn";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useUser } from "@/hooks/use-user";
import { useLocation } from "wouter";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { toast } = useToast();
  const { user, isLoading: isUserLoading } = useUser();
  const [, navigate] = useLocation();
  const [loginError, setLoginError] = useState<Error | null>(null);
  const [retryKey, setRetryKey] = useState(0);

  useEffect(() => {
    if (!isUserLoading && user) {
      navigate("/dashboard");
    }
  }, [user, isUserLoading, navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // TODO: Implement login logic (e.g., API call)
      // For demonstration, we'll show a success toast and navigate to dashboard
      toast({
        title: "Logged In!",
        description: "You have successfully logged in.",
      });
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      toast({
        title: "Login Failed",
        description: "Invalid email or password. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleGoogleError = (error: Error) => {
    setLoginError(error);
    toast({
      title: "Google Sign-In Failed",
      description: error.message,
      variant: "destructive",
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-background py-12 px-4 sm:px-6 lg:px-8">
      <Card className="max-w-md w-full p-8 shadow-lg">
        <h2 className="text-2xl font-semibold text-center mb-6">Login to Your Account</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700">
              Email Address
            </label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email address"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700">
              Password
            </label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
            />
          </div>
          <Button type="submit" className="w-full mb-4">
            Login
          </Button>
        </form>
        <div className="flex items-center justify-center mb-4">
          <span className="border-b w-1/5 lg:w-1/4"></span>
          <span className="text-xs text-center text-gray-500 uppercase mx-2">
            or
          </span>
          <span className="border-b w-1/5 lg:w-1/4"></span>
        </div>
        <GoogleSignInButton onError={handleGoogleError} />
        {loginError && (
          <div className="mt-4 text-center text-red-600">
            {loginError.message}
          </div>
        )}
        <div className="mt-4 text-center">
          Don't have an account?{" "}
          <Button variant="link" onClick={() => navigate("/signup")}>
            Sign Up
          </Button>
        </div>
      </Card>
    </div>
  );
}