import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { usePricingSheets } from "../hooks/use-pricing";
import { Card } from "@/components/ui/card";
import { UploadCloud, AlertCircle, Loader2, X, Eye, Pencil, Trash2 } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';

// Initialize PDF.js with worker using Vite's import.meta.url
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv"
];

const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB

export default function PricingSheetUpload() {
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(false);
  const { upload, deleteSheet, renameSheet, sheets, isLoading: isLoadingList } = usePricingSheets();
  const { toast } = useToast();
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<{ type: 'upload' | null; message: string | null }>({ type: null, message: null });
  const [selectedSheet, setSelectedSheet] = useState<any>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState("");
  const [previewContent, setPreviewContent] = useState<any>(null);

  useEffect(() => {
    if (selectedSheet && isPreviewOpen) {
      renderFilePreview(selectedSheet.content);
    }
  }, [selectedSheet, isPreviewOpen]);

  const renderPDFPreview = async (base64Content: string) => {
    try {
      const binaryString = window.atob(base64Content);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      
      const loadingTask = pdfjsLib.getDocument({ data: bytes });
      const pdfDoc = await loadingTask.promise;
      const pageCount = pdfDoc.numPages;
      const page = await pdfDoc.getPage(1);
      
      const viewport = page.getViewport({ scale: 1.0 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      
      await page.render({
        canvasContext: context!,
        viewport: viewport
      }).promise;
      
      setPreviewContent(
        <div className="space-y-4">
          <p className="text-sm text-muted-foreground">
            Showing page 1 of {pageCount} pages
          </p>
          <div className="overflow-auto max-h-[600px]">
            <img 
              src={canvas.toDataURL()}
              alt="PDF preview"
              className="max-w-full h-auto"
            />
          </div>
        </div>
      );
    } catch (error) {
      console.error('Error rendering PDF:', error);
      setPreviewContent(
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>Failed to render PDF preview</AlertDescription>
        </Alert>
      );
    }
  };

  const renderSpreadsheetPreview = (base64Content: string) => {
    try {
      const binaryString = window.atob(base64Content);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const workbook = XLSX.read(bytes, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      setPreviewContent(
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                {data[0].map((cell: any, index: number) => (
                  <th key={index} className="border p-2 bg-muted">
                    {cell}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.slice(1).map((row: any, rowIndex: number) => (
                <tr key={rowIndex}>
                  {row.map((cell: any, cellIndex: number) => (
                    <td key={cellIndex} className="border p-2">
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } catch (error) {
      console.error('Error rendering spreadsheet:', error);
      setPreviewContent(<p>Error rendering spreadsheet preview</p>);
    }
  };

  const renderDocxPreview = async (base64Content: string) => {
    try {
      const binaryString = window.atob(base64Content);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const result = await mammoth.convertToHtml({ arrayBuffer: bytes.buffer });
      setPreviewContent(
        <div 
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: result.value }}
        />
      );
    } catch (error) {
      console.error('Error rendering DOCX:', error);
      setPreviewContent(<p>Error rendering DOCX preview</p>);
    }
  };

  const renderFilePreview = async (content: any) => {
    if (!content?.raw || !content?.metadata?.mimetype) {
      setPreviewContent(<p>No content available for preview</p>);
      return;
    }

    const base64Content = content.raw;
    const mimetype = content.metadata.mimetype;

    try {
      switch (mimetype) {
        case 'application/pdf':
          await renderPDFPreview(base64Content);
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'text/csv':
          renderSpreadsheetPreview(base64Content);
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          await renderDocxPreview(base64Content);
          break;
        default:
          setPreviewContent(<p>Unsupported file type for preview</p>);
      }
    } catch (error) {
      console.error('Error rendering preview:', error);
      setPreviewContent(<p>Error rendering preview</p>);
    }
  };

  const resetForm = () => {
    setFile(null);
    setName("");
    setIsDragging(false);
    setError({ type: null, message: null });
    setUploadProgress(false);
  };

  const validateFile = (file: File) => {
    setError({ type: null, message: null });
    
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError({ 
        type: 'upload', 
        message: "Please upload a PDF, DOCX, Excel (.xlsx), or CSV file" 
      });
      toast({
        title: "Invalid file type",
        description: "Please upload a PDF, DOCX, Excel (.xlsx), or CSV file",
        variant: "destructive",
      });
      return false;
    }

    if (file.size > FILE_SIZE_LIMIT) {
      setError({ 
        type: 'upload', 
        message: "Maximum file size is 5MB" 
      });
      toast({
        title: "File too large",
        description: "Maximum file size is 5MB",
        variant: "destructive",
      });
      return false;
    }

    return true;
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && validateFile(droppedFile)) {
      setFile(droppedFile);
      const fileName = droppedFile.name.replace(/\.[^/.]+$/, "");
      setName(fileName);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file || !name) return;

    setIsUploading(true);
    setError({ type: null, message: null });
    setUploadProgress(true);
    
    try {
      const response = await upload(file, name);
      toast({
        title: "Success!",
        description: "Your pricing sheet has been uploaded successfully",
      });
      resetForm();
    } catch (error: any) {
      const errorMessage = error?.message || "There was an error uploading your pricing sheet";
      setError({ type: 'upload', message: errorMessage });
      toast({
        title: "Upload Failed",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(false);
    }
  };

  const handleDelete = async (sheet: any) => {
    try {
      await deleteSheet(sheet.id);
      toast({
        title: "Success",
        description: "Pricing sheet deleted successfully",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error?.message || "Failed to delete pricing sheet",
        variant: "destructive",
      });
    }
  };

  const handleRename = async (sheet: any) => {
    try {
      await renameSheet(sheet.id, newName);
      setIsRenaming(false);
      setNewName("");
      toast({
        title: "Success",
        description: "Pricing sheet renamed successfully",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error?.message || "Failed to rename pricing sheet",
        variant: "destructive",
      });
    }
  };

  return (
    <ErrorBoundary>
      <Card className="p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div
            onDrop={handleDrop}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDragging(true);
            }}
            onDragLeave={() => setIsDragging(false)}
            className={cn(
              "relative border-2 border-dashed rounded-lg p-8 text-center transition-colors",
              isDragging ? "border-primary bg-primary/5" : "border-border",
              file ? "bg-success/5" : "",
              isLoadingList ? "opacity-50 pointer-events-none" : "",
              error?.type === 'upload' ? "border-destructive/50 bg-destructive/5" : ""
            )}
          >
            {file && (
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="absolute top-2 right-2"
                onClick={resetForm}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
            
            <UploadCloud 
              className={cn(
                "mx-auto h-12 w-12",
                file ? "text-success" : "text-muted-foreground",
                error?.type === 'upload' && "text-destructive"
              )}
            />
            <div className="mt-4 space-y-2">
              <p className="text-sm font-medium">
                {file ? file.name : "Upload your pricing sheet"}
              </p>
              <p className="text-sm text-muted-foreground">
                Drag and drop your pricing sheet here, or click to browse
              </p>
              <p className="text-sm text-muted-foreground">
                Supported formats: PDF, DOCX, Excel (.xlsx), CSV
              </p>
              <p className="text-xs text-muted-foreground">
                Maximum file size: 5MB
              </p>
            </div>
            <Input
              type="file"
              accept=".pdf,.docx,.xlsx,.csv"
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (selectedFile && validateFile(selectedFile)) {
                  setFile(selectedFile);
                  const fileName = selectedFile.name.replace(/\.[^/.]+$/, "");
                  setName(fileName);
                }
              }}
              className="mt-4"
              disabled={isLoadingList || isUploading}
            />
          </div>

          {error?.message && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error.message}</AlertDescription>
            </Alert>
          )}

          <div className="space-y-2">
            <Label htmlFor="name">Sheet Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter a descriptive name for this pricing sheet"
              required
              disabled={isLoadingList || isUploading}
            />
          </div>

          <Alert>
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {isLoadingList ? 'Loading pricing sheets...' : 
                uploadProgress ? 'Processing your pricing sheet...' :
                `Currently ${sheets.length} pricing sheet${sheets.length !== 1 ? 's' : ''} available`}
            </AlertDescription>
          </Alert>

          <Button 
            type="submit" 
            disabled={!file || !name || isUploading || isLoadingList}
            className="w-full"
          >
            {isUploading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Uploading...
              </>
            ) : (
              <>
                <UploadCloud className="mr-2 h-4 w-4" />
                Upload Pricing Sheet
              </>
            )}
          </Button>
        </form>

        {sheets.length > 0 && (
          <div className="mt-8 space-y-4">
            {sheets.map((sheet) => (
              <div key={sheet.id} className="flex items-center justify-between border p-4 rounded-lg">
                <div className="flex-1">
                  {isRenaming && selectedSheet?.id === sheet.id ? (
                    <div className="flex items-center space-x-2">
                      <Input
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        placeholder="Enter new name"
                        className="max-w-[200px]"
                      />
                      <Button 
                        size="sm"
                        onClick={() => handleRename(sheet)}
                        disabled={!newName}
                      >
                        Save
                      </Button>
                      <Button
                        size="sm"
                        variant="ghost"
                        onClick={() => {
                          setIsRenaming(false);
                          setNewName("");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <p className="font-medium">{sheet.name}</p>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      setSelectedSheet(sheet);
                      setIsPreviewOpen(true);
                    }}
                  >
                    <Eye className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      setSelectedSheet(sheet);
                      setIsRenaming(true);
                      setNewName(sheet.name);
                    }}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleDelete(sheet)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}

        <Dialog open={isPreviewOpen} onOpenChange={setIsPreviewOpen}>
          <DialogContent className="max-w-4xl">
            <DialogHeader>
              <DialogTitle>
                {selectedSheet?.name}
              </DialogTitle>
              <DialogDescription>
                Uploaded on {new Date(selectedSheet?.createdAt).toLocaleDateString()}
              </DialogDescription>
            </DialogHeader>
            <div className="mt-4">
              {previewContent}
            </div>
            <DialogFooter>
              <Button onClick={() => setIsPreviewOpen(false)}>Close</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </Card>
    </ErrorBoundary>
  );
}