import { useEffect } from "react";
import Cal, { getCalApi } from "@calcom/embed-react";
import { Header } from "@/components/Header";

export default function BookCall() {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: {
          branding: {
            brandColor: "hsl(203, 89%, 53%)",
          },
        },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="p-6 pt-12">
        <div className="max-w-2xl mx-auto text-center mb-8">
          <h1 className="text-3xl font-bold mb-2 text-gradient">
            Schedule a Free Consultation
          </h1>
          <p className="text-muted-foreground">
            Let's discuss how we can help transform your business
          </p>
        </div>

        <div className="max-w-6xl mx-auto p-6">
          <div className="h-[600px] relative">
            <Cal
              namespace="15min"
              calLink="proposalstream/15min"
              style={{
                width: "100%",
                height: "100%",
                overflow: "scroll",
                borderRadius: "0.5rem",
              }}
              config={{
                layout: "month_view",
                styles: {
                  branding: {
                    brandColor: "hsl(203, 89%, 53%)",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
