import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { useUser } from "@/hooks/use-user";
import { useLocation } from "wouter";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { AuthForm } from "@/components/AuthForm";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ArrowRight, Check, Zap, FileText } from "lucide-react";
import { Header } from "@/components/Header";
import { InteractiveQA } from "@/components/InteractiveQA";
import { ServiceOfferings } from "@/components/ServiceOfferings";
import { ServiceSelectionFlow } from "@/components/ServiceSelection/ServiceSelectionFlow";

// Add gtag type definition
declare global {
  interface Window {
    gtag: (command: string, ...args: any[]) => void;
  }
}

interface GoogleCredentialResponse {
  credential: string;
}

interface GoogleSignInButtonProps {
  onError: (error: Error) => void;
}

interface GoogleSignInFallbackProps {
  error: Error;
  onRetry: () => void;
}

const RETRY_DELAYS = [1000, 2000, 4000, 8000];

function GoogleSignInButton({ onError }: GoogleSignInButtonProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isGoogleInitialized, setIsGoogleInitialized] = useState(false);
  const [initializationAttempt, setInitializationAttempt] = useState(0);
  const [initError, setInitError] = useState<Error | null>(null);
  const { handleGoogleLogin } = useUser();
  const { toast } = useToast();

  const initializeGoogleSignIn = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 100));

      if (!window.google?.accounts?.id) {
        console.debug('Google Sign-In SDK not loaded, retrying...', {
          attempt: initializationAttempt,
          window: typeof window !== 'undefined',
          google: Boolean(window.google),
          accounts: Boolean(window.google?.accounts),
        });
        
        if (initializationAttempt >= RETRY_DELAYS.length) {
          throw new Error("Failed to load Google Sign-In after multiple attempts");
        }

        const delay = RETRY_DELAYS[initializationAttempt];
        setTimeout(() => {
          setInitializationAttempt(prev => prev + 1);
          initializeGoogleSignIn();
        }, delay);
        return;
      }

      const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
      if (!clientId) {
        throw new Error("Google Client ID not configured");
      }

      const origin = window.location.origin;
      console.debug('Initializing Google Sign-In:', {
        origin,
        clientId: clientId.substring(0, 8) + '...',
      });

      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: async (response: GoogleCredentialResponse) => {
          try {
            setIsLoading(true);
            await handleGoogleLogin(response.credential);
            // Track successful Google sign in
            window.gtag('event', 'login', {
              method: 'Google'
            });
          } catch (error) {
            console.error('Google Sign-In error:', error);
            onError(error instanceof Error ? error : new Error('Authentication failed'));
            // Track failed Google sign in
            window.gtag('event', 'exception', {
              description: 'Google Sign-In Error',
              fatal: false
            });
          } finally {
            setIsLoading(false);
          }
        },
        allowed_parent_origin: origin,
        auto_select: false,
        cancel_on_tap_outside: true,
      });

      setIsGoogleInitialized(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Google Sign-In initialization error:", error);
      setInitError(error instanceof Error ? error : new Error("Failed to initialize Google Sign-In"));
      setIsLoading(false);
      // Track initialization error
      window.gtag('event', 'exception', {
        description: 'Google Sign-In Initialization Error',
        fatal: false
      });
    }
  };

  useEffect(() => {
    const buttonElement = document.getElementById("google-signin");
    if (isGoogleInitialized && window.google?.accounts?.id && buttonElement) {
      window.google.accounts.id.renderButton(buttonElement, {
        theme: "outline",
        size: "large",
        type: "standard",
        text: "signin_with",
        shape: "rectangular",
        width: 300,
      });
    }
  }, [isGoogleInitialized]);

  useEffect(() => {
    initializeGoogleSignIn();
    return () => {
      if (window.google?.accounts?.id && isGoogleInitialized) {
        window.google.accounts.id.cancel();
      }
    };
  }, [handleGoogleLogin, initializationAttempt]);

  if (initError) {
    return (
      <div className="p-4 text-center">
        <p className="text-destructive">{initError.message}</p>
        <Button 
          onClick={() => {
            setInitError(null);
            setInitializationAttempt(0);
            initializeGoogleSignIn();
          }}
          className="mt-4"
        >
          Retry
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="relative min-h-[48px]">
      <div id="google-signin" className="w-full flex justify-center"></div>
    </div>
  );
}

function GoogleSignInFallback({ error, onRetry }: GoogleSignInFallbackProps) {
  return (
    <div className="p-4 text-center">
      <p className="text-destructive mb-4">{error.message}</p>
      <Button onClick={onRetry}>Try Again</Button>
    </div>
  );
}

function LandingPageContent() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [email, setEmail] = useState("");
  const [signInError, setSignInError] = useState<Error | null>(null);
  const { toast } = useToast();
  const { user, isLoading: isUserLoading } = useUser();
  const [, navigate] = useLocation();
  const [retryKey, setRetryKey] = useState(0);

  useEffect(() => {
    if (!isUserLoading && user) {
      window.gtag('event', 'login_success', {
        method: 'redirect'
      });
      window.location.replace('/dashboard');
    }
  }, [user, isUserLoading]);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const scrollTo = searchParams.get('scrollTo');
    if (scrollTo === 'services') {
      setTimeout(() => {
        document.querySelector('#services')?.scrollIntoView({ behavior: 'smooth' });
        // Clean up the URL
        window.history.replaceState({}, '', '/');
      }, 100);
    }
  }, []);

  const handleRetry = () => {
    setSignInError(null);
    setRetryKey(prev => prev + 1);
  };

  const handleWaitlist = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/waitlist", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      
      const result = await response.json();
      
      if (result.status === "error") {
        throw new Error(result.message || "Failed to join waitlist");
      }

      window.gtag('event', 'generate_lead', {
        event_category: 'engagement',
        event_label: 'waitlist'
      });

      toast({
        title: "Welcome aboard! 🎉",
        description: "You've been added to our waitlist. We'll notify you when we have updates.",
      });
      setEmail("");
    } catch (error) {
      console.error('Waitlist error:', error);
      window.gtag('event', 'exception', {
        description: 'Waitlist Error',
        fatal: false
      });
      toast({
        title: "Oops!",
        description: error instanceof Error ? error.message : "We couldn't add you to the waitlist. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (isLoading || isUserLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center space-y-4">
          <h2 className="text-xl font-semibold">Something went wrong</h2>
          <p className="text-muted-foreground">{error.message}</p>
          <Button onClick={() => window.location.reload()}>Retry</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background">
      <Header />

      {/* Hero Section */}
      <section className="relative min-h-[calc(90vh-4rem)] flex items-center bg-gradient-to-b from-background via-secondary/20 to-background">
        <div className="container mx-auto px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
            {/* Left side - Content */}
            <div className="text-left pt-12">
              <h1 className="text-5xl md:text-6xl font-bold mb-4 text-gradient leading-[1.15] tracking-tight">
                Transform Your 
                <br />
                Digital Business
              </h1>
              <p className="text-xl md:text-2xl mb-8 text-muted-foreground">
                Streamline your contracting business with modern tools. Win more bids, respond faster to clients, and grow your commercial contracting company.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <Button size="lg" className="text-lg px-8 py-6 bg-primary text-primary-foreground hover:bg-primary/90" onClick={() => navigate('/products')}>
                  See How It Works
                  <ArrowRight className="ml-2 h-5 w-5" />
                </Button>
                <Button 
                  size="lg" 
                  variant="outline" 
                  className="text-lg px-8 py-6 text-foreground hover:bg-accent hover:text-accent-foreground" 
                  onClick={() => navigate('/book-call')}
                >
                  Book Free Consultation
                </Button>
              </div>
              <div className="mt-8 space-y-4">
                <div className="flex items-center gap-2">
                  <Check className="h-5 w-5 text-primary" />
                  <span className="text-muted-foreground">Generate professional quotes in under 2 minutes</span>
                </div>
                <div className="flex items-center gap-2">
                  <Check className="h-5 w-5 text-primary" />
                  <span className="text-muted-foreground">Perfect for roofing, landscaping, and facility maintenance</span>
                </div>
                <div className="flex items-center gap-2">
                  <Check className="h-5 w-5 text-primary" />
                  <span className="text-muted-foreground">Win more commercial contracts with faster responses</span>
                </div>
              </div>
            </div>

            {/* Right side - Interactive QA */}
            <div className="relative pt-8">
              <InteractiveQA />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-24 bg-gradient-to-b from-background via-secondary/20 to-background">
        <div className="container mx-auto px-8">
          <h2 className="text-4xl font-bold mb-12 text-center">Why Contractors Choose Us</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <Card className="p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
              <Zap className="h-12 w-12 text-primary mb-4" />
              <h3 className="text-xl font-semibold mb-2">Built for Speed</h3>
              <p className="text-muted-foreground">
                Deliver professional quotes in minutes. Our automated system helps you respond faster to RFPs and win more commercial contracts.
              </p>
            </Card>
            <Card className="p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
              <Check className="h-12 w-12 text-primary mb-4" />
              <h3 className="text-xl font-semibold mb-2">Property Manager Approved</h3>
              <p className="text-muted-foreground">
                Created by property management professionals who understand what makes vendor relationships successful. Every feature is built with this insight.
              </p>
            </Card>
            <Card className="p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
              <FileText className="h-12 w-12 text-primary mb-4" />
              <h3 className="text-xl font-semibold mb-2">Smart Templates</h3>
              <p className="text-muted-foreground">
                Industry-specific templates for roofing, landscaping, and maintenance, designed to showcase your services effectively to property managers.
              </p>
            </Card>
          </div>
        </div>
      </section>

      {/* Service Offerings Preview */}
      <section id="services" className="py-24 bg-gradient-to-b from-background via-secondary/20 to-background">
        <div className="container mx-auto px-8">
          <h2 className="text-4xl font-bold mb-4 text-center">Services for Commercial Contractors</h2>
          <p className="text-xl text-muted-foreground mb-12 text-center">
            Modern solutions to help you win more bids and grow your contracting business
          </p>
          <ServiceOfferings />
          <div className="text-center mt-12">
            <Button 
              size="lg" 
              onClick={() => navigate('/products')} 
              className="text-lg px-8 bg-primary text-primary-foreground hover:bg-primary/90"
            >
              View All Services
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
      </section>

      {/* Service Selection Section */}
      <section className="py-24 bg-gradient-to-b from-background via-secondary/20 to-background">
        <div className="container mx-auto px-8">
          <h2 className="text-4xl font-bold mb-4 text-center">Get Started Today</h2>
          <p className="text-xl text-muted-foreground mb-12 text-center">
            Select the services you're interested in and schedule a consultation
          </p>
          <ServiceSelectionFlow />
        </div>
      </section>
    </div>
  );
}

export default function LandingPage() {
  useEffect(() => {
    // Track page view on component mount
    window.gtag('config', 'G-ZZW26HF1JN', {
      page_path: window.location.pathname,
      page_title: 'Landing Page'
    });
  }, []);

  return (
    <ErrorBoundary>
      <LandingPageContent />
    </ErrorBoundary>
  );
}