import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useUser } from "../hooks/use-user";
import { useToast } from "@/hooks/use-toast";
import { useLocation } from "wouter";
import * as emailjs from '@emailjs/browser';

interface GoogleCredentialResponse {
  credential: string;
}

interface GoogleSignInButtonProps {
  onError: (error: Error) => void;
}

interface GoogleSignInFallbackProps {
  error: Error;
  onRetry: () => void;
}

const RETRY_DELAYS = [1000, 2000, 4000, 8000];

export function GoogleSignInButton({ onError }: GoogleSignInButtonProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isGoogleInitialized, setIsGoogleInitialized] = useState(false);
  const [initializationAttempt, setInitializationAttempt] = useState(0);
  const [initError, setInitError] = useState<Error | null>(null);
  const { handleGoogleLogin } = useUser();
  const { toast } = useToast();
  const [, navigate] = useLocation();

  const handleCredentialResponse = async (response: GoogleCredentialResponse) => {
    try {
      // Decode the JWT to get user information
      const base64Url = response.credential.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      
      const { email, name } = JSON.parse(jsonPayload);

      // Add user to waitlist
      const waitlistResponse = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name,
          credential: response.credential
        }),
      });

      if (!waitlistResponse.ok) {
        throw new Error('Failed to join waitlist');
      }

      // Send confirmation email
      await emailjs.send(
        import.meta.env.VITE_EMAILJS_SERVICE_ID,
        import.meta.env.VITE_EMAILJS_TEMPLATE_ID,
        {
          to_email: email,
          to_name: name,
        },
        import.meta.env.VITE_EMAILJS_PUBLIC_KEY
      );

      toast({
        title: "Success!",
        description: "You've been added to our waitlist.",
      });

      navigate('/waitlist-thank-you');
    } catch (error) {
      console.error('Google Sign-in error:', error);
      onError(error as Error);
      toast({
        title: "Error",
        description: "Failed to join waitlist. Please try again.",
        variant: "destructive",
      });
    }
  };

  const initializeGoogleSignIn = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAYS[initializationAttempt]));
      
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      // @ts-ignore
      google.accounts.id.renderButton(
        document.getElementById("googleButton"),
        { theme: "outline", size: "large", width: 280 }
      );

      setIsGoogleInitialized(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to initialize Google Sign-In:', error);
      setInitError(error as Error);
      if (initializationAttempt < RETRY_DELAYS.length - 1) {
        setInitializationAttempt(prev => prev + 1);
      } else {
        setIsLoading(false);
        onError(error as Error);
      }
    }
  };

  useEffect(() => {
    if (!isGoogleInitialized && initializationAttempt < RETRY_DELAYS.length) {
      initializeGoogleSignIn();
    }
  }, [initializationAttempt]);

  if (initError && !isGoogleInitialized) {
    return <GoogleSignInFallback error={initError} onRetry={() => setInitializationAttempt(0)} />;
  }

  return (
    <div>
      {isLoading ? (
        <Button disabled className="w-[280px]">
          Loading...
        </Button>
      ) : (
        <div id="googleButton"></div>
      )}
    </div>
  );
}

export function GoogleSignInFallback({ error, onRetry }: GoogleSignInFallbackProps) {
  return (
    <Button onClick={onRetry} variant="outline" className="w-[280px]">
      Retry Google Sign-In
    </Button>
  );
}
