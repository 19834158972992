// /Users/guyma/code/PS-pMVP/client/src/pages/FutureProjects.tsx
import { Header } from "@/components/Header";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useLocation } from "wouter";
import { ArrowRight, Check } from "lucide-react";
import { Pricing } from "@/components/Pricing";

export default function FutureProjects() {
  const [, navigate] = useLocation();

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="py-24">
        <div className="container mx-auto px-8">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-4">Future Projects</h1>
            <p className="text-xl text-muted-foreground">
              Upcoming solutions to revolutionize vendor-property manager communication
            </p>
          </div>

          {/* Proposal Management System */}
          <div className="mb-24">
            <Card className="p-8 bg-card/80 backdrop-blur-sm shadow-xl border-primary/10">
              <div className="max-w-3xl mx-auto mb-16">
                <h2 className="text-3xl font-bold mb-4">Proposal Management System</h2>
                <p className="text-lg text-muted-foreground mb-8">
                  A comprehensive solution for streamlining the entire proposal lifecycle, from quote collection to final approval.
                </p>
                <div className="space-y-4">
                  <div className="flex items-center gap-3">
                    <Check className="h-6 w-6 text-primary" />
                    <span className="text-muted-foreground">Automated quote collection and processing</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <Check className="h-6 w-6 text-primary" />
                    <span className="text-muted-foreground">Intelligent proposal generation</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <Check className="h-6 w-6 text-primary" />
                    <span className="text-muted-foreground">Real-time collaboration tools</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <Check className="h-6 w-6 text-primary" />
                    <span className="text-muted-foreground">Digital signature and approval workflow</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <Check className="h-6 w-6 text-primary" />
                    <span className="text-muted-foreground">Analytics and reporting dashboard</span>
                  </div>
                </div>
              </div>

              {/* Pricing Section */}
              <div className="mt-16">
                <Pricing />
              </div>
            </Card>
          </div>

          {/* CTA Section */}
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4">Interested in Early Access?</h2>
            <p className="text-xl text-muted-foreground mb-8">
              Join our waitlist to be the first to know when these features become available
            </p>
            <Button 
              size="lg" 
              onClick={() => navigate('/contact')} 
              className="text-lg px-8 bg-primary text-primary-foreground hover:bg-primary/90"
            >
              Join Waitlist
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}