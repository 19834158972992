import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { ArrowLeft, Check, Phone, Calendar } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import Cal, { getCalApi } from "@calcom/embed-react";

interface ConfirmationStepProps {
  formData: {
    selectedServices: string[];
    contactDetails: {
      email: string;
      name: string;
      companyName: string;
      phoneNumber?: string;
    };
    message: string;
  };
  onBack: () => void;
}

export function ConfirmationStep({
  formData,
  onBack,
}: ConfirmationStepProps) {
  const [preferredContact, setPreferredContact] = useState<"call" | "meeting">("meeting");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (preferredContact === "meeting") {
      (async function () {
        const cal = await getCalApi();
        cal("ui", {
          styles: {
            branding: {
              brandColor: "hsl(203, 89%, 53%)",
            },
          },
          hideEventTypeDetails: false,
          layout: "month_view",
        });
        
        // Add event listener for booking success
        cal("on", {
          action: "bookingSuccessful",
          callback: async (event) => {
            try {
              // Update service request with booking details and trigger estimate
              const response = await fetch("/api/service-requests/webhook/calendar", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  type: "booking_created",
                  payload: {
                    email: formData.contactDetails.email,
                    bookingDetails: event,
                    contactDetails: formData.contactDetails,
                    selectedServices: formData.selectedServices,
                    message: formData.message
                  }
                }),
              });

              if (!response.ok) {
                throw new Error("Failed to process booking");
              }

              toast({
                title: "Meeting Scheduled!",
                description: "We'll send you a custom estimate shortly. Check your email for meeting details.",
              });
            } catch (error) {
              console.error('Failed to process booking:', error);
              toast({
                title: "Error",
                description: "Failed to process booking. Please try again or contact support.",
                variant: "destructive",
              });
            }
          },
        });
      })();
    }
  }, [preferredContact, formData]);

  const handleSubmitPhoneRequest = async () => {
    setIsSubmitting(true);
    try {
      // Update service request with call preference and trigger estimate
      const response = await fetch("/api/service-requests/quick-call", {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          email: formData.contactDetails.email,
          contactDetails: formData.contactDetails,
          selectedServices: formData.selectedServices,
          message: formData.message,
          preferredContact: "call"
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit request");
      }

      toast({
        title: "Request Submitted!",
        description: "We'll call you shortly with your custom estimate. This is not a final quote, just an initial estimate based on your requirements.",
      });
    } catch (error: any) {
      console.error('Service request error:', error);
      toast({
        title: "Error",
        description: "Failed to submit request. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-2">Final Step: Choose Your Consultation Method</h2>
        <p className="text-muted-foreground">
          Select how you'd like to receive your custom estimate. After this step, you'll receive an email with your initial estimate (not a final quote).
        </p>
      </div>

      <RadioGroup
        value={preferredContact}
        onValueChange={(value: "call" | "meeting") => setPreferredContact(value)}
        className="grid gap-4"
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="meeting" id="meeting" />
          <Label htmlFor="meeting" className="flex items-center">
            <Calendar className="mr-2 h-4 w-4" />
            Book a Detailed Consultation (30 min)
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="call" id="call" />
          <Label htmlFor="call" className="flex items-center">
            <Phone className="mr-2 h-4 w-4" />
            Get a Quick Estimate (5 min call)
          </Label>
        </div>
      </RadioGroup>

      {preferredContact === "meeting" ? (
        <div className="h-[600px] relative">
          <Cal
            namespace="30min"
            calLink="proposalstream/30min"
            style={{
              width: "100%",
              height: "100%",
              overflow: "scroll",
              borderRadius: "0.5rem",
            }}
            config={{
              layout: "month_view",
              styles: {
                branding: {
                  brandColor: "hsl(203, 89%, 53%)",
                },
              },
              name: formData.contactDetails.name,
              email: formData.contactDetails.email,
              notes: `Company: ${formData.contactDetails.companyName}\nServices: ${formData.selectedServices.join(", ")}\nMessage: ${formData.message}`,
            }}
          />
        </div>
      ) : (
        <div className="flex justify-center">
          <Button 
            onClick={handleSubmitPhoneRequest} 
            disabled={isSubmitting}
            size="lg"
          >
            {isSubmitting ? (
              <>
                <div className="animate-spin mr-2">⏳</div>
                Submitting...
              </>
            ) : (
              <>
                <Phone className="mr-2 h-4 w-4" />
                Request Quick Call
              </>
            )}
          </Button>
        </div>
      )}

      <div className="mt-6">
        <Button variant="outline" onClick={onBack}>
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </Button>
      </div>
    </div>
  );
}