import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { z } from "zod";
import { useCompanyProfile } from "../hooks/use-company-profile";
import { Loader2, Upload } from "lucide-react";
import { cn } from "@/lib/utils";
import { useState } from "react";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

// Local validation schema
const companyProfileSchema = z.object({
  logoUrl: z.string().optional(),
  brandColor: z.string().optional(),
  legalName: z.string().min(1, "Legal name is required"),
  businessName: z.string().min(1, "Business name is required"),
  officePhone: z.string().min(1, "Office phone is required"),
  website: z.string().url().optional(),
});

type CompanyProfileFormData = z.infer<typeof companyProfileSchema>;

export function CompanyProfileForm() {
  const { toast } = useToast();
  const { profile, isLoading, update } = useCompanyProfile();
  const [previewUrl, setPreviewUrl] = useState<string | null>(profile?.logoUrl || null);
  const [isUploading, setIsUploading] = useState(false);

  const form = useForm<CompanyProfileFormData>({
    resolver: zodResolver(companyProfileSchema),
    defaultValues: {
      logoUrl: profile?.logoUrl || "",
      brandColor: profile?.brandColor || "#000000",
      legalName: profile?.legalName || "",
      businessName: profile?.businessName || "",
      officePhone: profile?.officePhone || "",
      website: profile?.website || "",
    },
  });

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
      toast({
        title: "Invalid file type",
        description: "Please upload a PNG or JPEG image",
        variant: "destructive",
      });
      return;
    }

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      toast({
        title: "File too large",
        description: "Image must be less than 2MB",
        variant: "destructive",
      });
      return;
    }

    try {
      // Convert to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setPreviewUrl(base64String);
        form.setValue("logoUrl", base64String);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to process image",
        variant: "destructive",
      });
    }
  };

  const onSubmit = async (data: CompanyProfileFormData) => {
    try {
      setIsUploading(true);
      await update(data);
      toast({
        title: "Success",
        description: "Company profile updated successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update company profile",
        variant: "destructive",
      });
    } finally {
      setIsUploading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <Card className="p-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          {/* Logo Upload */}
          <FormField
            control={form.control}
            name="logoUrl"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Company Logo</FormLabel>
                <FormControl>
                  <div className="space-y-4">
                    <div
                      className={cn(
                        "border-2 border-dashed rounded-lg p-4 text-center transition-colors",
                        "hover:border-primary/50 hover:bg-muted"
                      )}
                    >
                      {previewUrl ? (
                        <div className="space-y-4">
                          <img
                            src={previewUrl}
                            alt="Logo preview"
                            className="mx-auto max-h-40 rounded-lg"
                          />
                          <Button
                            type="button"
                            variant="outline"
                            onClick={() => {
                              setPreviewUrl(null);
                              form.setValue("logoUrl", "");
                            }}
                          >
                            Remove Logo
                          </Button>
                        </div>
                      ) : (
                        <div className="space-y-2">
                          <Upload className="mx-auto h-8 w-8 text-muted-foreground" />
                          <div className="text-sm text-muted-foreground">
                            <label
                              htmlFor="logo-upload"
                              className="relative cursor-pointer rounded-md font-semibold text-primary hover:text-primary/80"
                            >
                              <span>Upload a file</span>
                              <input
                                id="logo-upload"
                                type="file"
                                className="sr-only"
                                accept="image/png,image/jpeg"
                                onChange={handleImageChange}
                              />
                            </label>
                            <p>or drag and drop</p>
                            <p className="text-xs">PNG, JPG up to 2MB</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="legalName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Legal Name</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter legal business name" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="businessName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Business Name</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter business name" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="officePhone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Office Phone</FormLabel>
                <FormControl>
                  <Input {...field} type="tel" placeholder="Enter office phone" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="website"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Website (Optional)</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="example.com or www.example.com" />
                </FormControl>
                <FormDescription>
                  Enter your domain name - https:// will be added automatically if needed
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="brandColor"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Brand Color</FormLabel>
                <FormControl>
                  <Input {...field} type="color" className="h-10" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" className="w-full" disabled={isUploading}>
            {(form.formState.isSubmitting || isUploading) && (
              <Loader2 className="h-4 w-4 animate-spin mr-2" />
            )}
            Save Changes
          </Button>
        </form>
      </Form>
    </Card>
  );
}
