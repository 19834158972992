import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Check } from "lucide-react";
import { useState } from "react";

export function Pricing() {
  const [isAnnual, setIsAnnual] = useState(false);

  const plans = {
    starter: {
      monthly: 80,
      annual: 70 // $120 savings/year
    },
    professional: {
      monthly: 'TBD',
      annual: 'TBD' 
    }
  };

  const getPrice = (plan: keyof typeof plans) => {
    return isAnnual ? plans[plan].annual : plans[plan].monthly;
  };

  const getAnnualSavings = (plan: keyof typeof plans) => {
    if (plan === 'professional') return '';
    const monthly = plans[plan].monthly;
    const annual = plans[plan].annual;
    return (monthly * 12) - (annual * 12);
  };

  return (
    <section id="pricing" className="relative py-24 bg-gradient-to-b from-background via-secondary/20 to-background">
      <div className="container mx-auto px-8">
        <div className="mb-12">
          <h2 className="text-4xl font-bold mb-4 text-center">Simple, Transparent Pricing</h2>
          <p className="text-xl text-muted-foreground mb-8 text-center">Strengthen your business and clients with ProposalStream</p>
          
          <div className="flex items-center justify-center gap-3 mb-8">
            <span className={`text-sm ${!isAnnual ? 'text-foreground' : 'text-muted-foreground'}`}>Monthly</span>
            <button
              onClick={() => setIsAnnual(!isAnnual)}
              className="relative w-12 h-6 rounded-full bg-primary/20 transition-colors duration-200"
            >
              <div className={`absolute top-1 h-4 w-4 rounded-full bg-primary transition-transform duration-200 ${isAnnual ? 'translate-x-7' : 'translate-x-1'}`} />
            </button>
            <span className={`text-sm ${isAnnual ? 'text-foreground' : 'text-muted-foreground'}`}>
              Annual <span className="text-xs text-primary">(Save ${getAnnualSavings('starter')}/year)</span>
            </span>
          </div>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {/* Starter Plan - Instant Quote Automation */}
          <Card className="p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
            <div className="text-center mb-6">
              <h3 className="text-2xl font-bold mb-2">Instant Quote</h3>
              <div className="text-4xl font-bold mb-2">${getPrice('starter')}<span className="text-lg text-muted-foreground">/mo</span></div>
              {isAnnual && <div className="text-sm text-muted-foreground">Save ${getAnnualSavings('starter')}/year</div>}
              <p className="text-muted-foreground mt-2">Perfect for small contractors</p>
            </div>
            <div className="space-y-4 mb-6">
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Up to 50 quotes/month</span>
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Basic analytics</span>
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Email support</span>
              </div>
            </div>
            <Button className="w-full" variant="outline">Start Free Trial</Button>
          </Card>

          {/* Professional Plan - Instant Quote Automation + Instant Proposal Automation */}
          <Card className="p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary relative">
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
              <span className="bg-primary text-primary-foreground px-4 py-1 rounded-full text-sm">Most Popular</span>
            </div>
            <div className="text-center mb-6">
              <h3 className="text-2xl font-bold mb-2">Instant Quote & Proposal</h3>
              <div className="text-4xl font-bold mb-2">{getPrice('professional')}</div>
              {isAnnual && <div className="text-sm text-muted-foreground">{getAnnualSavings('professional')}</div>}
              <p className="text-muted-foreground mt-2">For growing businesses</p>
            </div>
            <div className="space-y-4 mb-6">
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Unlimited quotes</span>
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Advanced analytics</span>
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Priority support</span>
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Instant Proposal Automation</span>
              </div>
            </div>
            <Button className="w-full">Join Waitlist</Button>
          </Card>

          {/* Enterprise Plan */}
          <Card className="p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
            <div className="text-center mb-6">
              <h3 className="text-2xl font-bold mb-2">Enterprise</h3>
              <div className="text-4xl font-bold mb-2">Custom</div>
              <p className="text-muted-foreground">For large organizations</p>
            </div>
            <div className="space-y-4 mb-6">
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Custom quote volume</span>
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Custom integrations</span>
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>White-glove support</span>
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 text-primary mr-2" />
                <span>Docusign integration</span>
              </div>
            </div>
            <Button className="w-full" variant="outline">Contact Sales</Button>
          </Card>
        </div>
      </div>
    </section>
  );
}
