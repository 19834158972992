import { useState } from "react";
import { useProposals, useProposal } from "../hooks/use-proposal";
import { useProposalRevisions } from "../hooks/use-proposal-revisions";
import { usePricingSheets } from "../hooks/use-pricing";
import { mutate } from "swr";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { History, FileText, Loader2, Plus, PenLine, Trash2 } from "lucide-react";
import { useLocation } from "wouter";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/hooks/use-toast";
import PricingSheetUpload from "../components/PricingSheetUpload";
import { CompanyProfileForm } from "../components/CompanyProfileForm";
import { LogoutButton } from "../components/LogoutButton";
import { ErrorBoundary } from "../components/ErrorBoundary";
import type { PricingSheet, Proposal } from "../../../server/db/schema";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { cn } from "@/lib/utils";
import { EmbedSettings } from "@/components/EmbedSettings";

function formatDate(date: string | Date | null) {
  if (!date) return '';
  return new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

function ProposalCard({ proposal, onSelect, onResume, onDelete }: { 
  proposal: Proposal; 
  onSelect: (id: number) => void;
  onResume: (id: number) => void;
  onDelete: (id: number) => Promise<void>;
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const isDraft = proposal.status === 'draft';
  
  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(proposal.id);
    } catch (error) {
      console.error('Error in ProposalCard delete:', {
        error,
        proposalId: proposal.id,
        timestamp: new Date().toISOString()
      });
    } finally {
      setIsDeleting(false);
    }
  };
  
  return (
    <Card key={proposal.id} className="p-4 hover:border-primary/50 transition-colors">
      <div className="flex justify-between items-start mb-2">
        <div>
          <div className="flex items-center gap-2 mb-1">
            <h3 className="font-semibold">{proposal.clientName || 'Unnamed Draft'}</h3>
            <Badge 
              variant={isDraft ? 'secondary' : 'default'}
              className={cn(
                isDraft ? 'bg-muted text-muted-foreground' : ''
              )}
            >
              {isDraft ? 'Draft' : 'Complete'}
            </Badge>
          </div>
          <p className="text-sm text-muted-foreground">
            {proposal.totalPrice ? `$${proposal.totalPrice.toLocaleString()}` : 'No price set'}
          </p>
          <p className="text-sm text-muted-foreground">
            Created: {formatDate(proposal.createdAt)}
          </p>
          {isDraft && proposal.updatedAt && (
            <p className="text-sm text-muted-foreground">
              Last modified: {formatDate(proposal.updatedAt)}
            </p>
          )}
        </div>
        <div className="flex gap-2">
          {isDraft && (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onResume(proposal.id)}
              title="Resume Draft"
              disabled={isDeleting}
            >
              <PenLine className="h-4 w-4" />
            </Button>
          )}
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onSelect(proposal.id)}
            title="View History"
            disabled={isDeleting}
          >
            <History className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={handleDelete}
            title="Delete Proposal"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Trash2 className="h-4 w-4" />
            )}
          </Button>
        </div>
      </div>
    </Card>
  );
}

function SheetsList({ sheets, isLoading }: { sheets: PricingSheet[], isLoading: boolean }) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!sheets.length) {
    return (
      <div className="text-center p-4 mt-4">
        <p>No pricing sheets yet</p>
        <p className="text-sm text-muted-foreground mt-2">
          Upload your first pricing sheet using the form above
        </p>
      </div>
    );
  }

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      {sheets.map((sheet) => (
        <Card key={`sheet-${sheet.id}`} className="p-4">
          <div className="flex items-start justify-between">
            <div className="space-y-1">
              <h3 className="font-semibold">{sheet.name}</h3>
              <p className="text-sm text-muted-foreground">
                Created: {new Date(sheet.createdAt || '').toLocaleDateString()}
              </p>
            </div>
            <FileText className="h-4 w-4 text-muted-foreground" />
          </div>
        </Card>
      ))}
    </div>
  );
}

export default function Dashboard() {
  const [, navigate] = useLocation();
  const [selectedProposalId, setSelectedProposalId] = useState<number | null>(null);
  const [deletedItems, setDeletedItems] = useState<Set<number>>(new Set());
  const { proposals, error: proposalsError, isLoading: proposalsLoading } = useProposals();
  const { sheets, error: sheetsError, isLoading: sheetsLoading } = usePricingSheets();
  const { revisions, isLoading: revisionsLoading } = useProposalRevisions(selectedProposalId);
  const { toast } = useToast();
  const { delete: deleteProposal } = useProposal();

  const handleResumeProposal = (proposalId: number) => {
    console.log('Resuming proposal:', {
      proposalId,
      timestamp: new Date().toISOString()
    });
    navigate(`/create-proposal#draft=${proposalId}`);
  };

  const handleDeleteProposal = async (proposalId: number) => {
    try {
      setDeletedItems(prev => new Set([...prev, proposalId]));
      await deleteProposal(proposalId);
      // Use the same CACHE_KEY from useProposal.ts
      await mutate('/api/proposals');
      toast({
        title: "Success",
        description: "Proposal deleted successfully",
      });
    } catch (error: any) {
      console.error('Error deleting proposal:', {
        error: error.message,
        proposalId,
        timestamp: new Date().toISOString()
      });
      setDeletedItems(prev => {
        const newSet = new Set(prev);
        newSet.delete(proposalId);
        return newSet;
      });
      toast({
        title: "Error",
        description: error.message || "Failed to delete proposal",
        variant: "destructive",
      });
    }
  };

  // Filter out deleted items from the display
  const filteredProposals = proposals?.filter(p => !deletedItems.has(p.id)) || [];

  if (proposalsError || sheetsError) {
    return (
      <Alert variant="destructive" className="m-4">
        <AlertDescription>
          {proposalsError ? 'Failed to load proposals' : 'Failed to load pricing sheets'}
          <Button onClick={() => window.location.reload()} className="ml-4" variant="outline">
            Retry
          </Button>
        </AlertDescription>
      </Alert>
    );
  }

  if (proposalsLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Welcome to Your Dashboard</h1>
        <LogoutButton />
      </div>

      <Tabs defaultValue="proposals" className="space-y-4">
        <TabsList className="border-b border-border">
          <TabsTrigger 
            value="proposals"
            className="data-[state=active]:text-primary data-[state=active]:border-primary"
          >
            Proposals
          </TabsTrigger>
          <TabsTrigger value="pricing">Pricing Sheets</TabsTrigger>
          <TabsTrigger value="company">My Company</TabsTrigger>
          <TabsTrigger value="embed">Embed Settings</TabsTrigger>
        </TabsList>

        <TabsContent value="proposals">
          <ErrorBoundary>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold">Manage Proposals</h2>
              {sheets && sheets.length > 0 ? (
                <Button
                  variant="default"
                  className="bg-primary hover:bg-primary/90"
                  onClick={() => navigate("/create-proposal")}
                >
                  <Plus className="mr-2 h-4 w-4" />
                  Create Proposal
                </Button>
              ) : (
                <Alert>
                  <AlertDescription>
                    Please upload a pricing sheet before creating proposals
                  </AlertDescription>
                </Alert>
              )}
            </div>
            
            {filteredProposals.length > 0 ? (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filteredProposals.map((proposal) => (
                  <ErrorBoundary key={proposal.id}>
                    <ProposalCard
                      proposal={proposal}
                      onSelect={setSelectedProposalId}
                      onResume={handleResumeProposal}
                      onDelete={handleDeleteProposal}
                    />
                  </ErrorBoundary>
                ))}
              </div>
            ) : (
              <div className="text-center p-8">
                <p className="text-lg text-muted-foreground">No proposals created yet</p>
                <p className="mt-2 text-sm text-muted-foreground">
                  Start by creating your first proposal
                </p>
              </div>
            )}
          </ErrorBoundary>
        </TabsContent>

        <TabsContent value="pricing">
          <div className="space-y-6">
            <div className="prose max-w-none">
              <h2 className="text-2xl font-semibold mb-4">Manage Your Pricing</h2>
              <p className="text-muted-foreground mb-4">
                Upload and manage your pricing sheets to streamline your proposal creation process. 
                We support PDF, DOCX, Excel (.xlsx), and CSV formats to make it easy to import your existing pricing documents.
              </p>
            </div>

            <PricingSheetUpload />
            
            <ErrorBoundary>
              <SheetsList sheets={sheets} isLoading={sheetsLoading} />
            </ErrorBoundary>
          </div>
        </TabsContent>

        <TabsContent value="company">
          <div className="space-y-6">
            <div className="prose max-w-none">
              <h2 className="text-2xl font-semibold mb-4">Company Profile</h2>
              <p className="text-muted-foreground mb-4">
                Manage your company information and branding settings. This information will be used in your proposals.
              </p>
            </div>
            
            <ErrorBoundary>
              <CompanyProfileForm />
            </ErrorBoundary>
          </div>
        </TabsContent>

        <TabsContent value="embed">
          <EmbedSettings />
        </TabsContent>
      </Tabs>

      <Dialog open={!!selectedProposalId} onOpenChange={() => setSelectedProposalId(null)}>
        <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Revision History</DialogTitle>
          </DialogHeader>
          {revisionsLoading ? (
            <div className="flex justify-center p-4">
              <Loader2 className="h-6 w-6 animate-spin text-primary" />
            </div>
          ) : !revisions || revisions.length === 0 ? (
            <div className="text-center p-4">
              <p className="text-muted-foreground">No revision history available</p>
            </div>
          ) : (
            <div className="space-y-4">
              {revisions.map((revision) => (
                <Card key={revision.id} className="p-4">
                  <div className="flex justify-between mb-2">
                    <h4 className="font-semibold">Version {revision.version}</h4>
                    <p className="text-sm text-muted-foreground">
                      {formatDate(revision.createdAt)}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="text-sm">
                      <span className="font-medium">Project Details:</span>{" "}
                      {revision.projectDetails}
                    </p>
                    <p className="text-sm">
                      <span className="font-medium">Total Price:</span> $
                      {revision.totalPrice?.toLocaleString()}
                    </p>
                    {revision.customMessage && (
                      <p className="text-sm">
                        <span className="font-medium">Custom Message:</span>{" "}
                        {revision.customMessage}
                      </p>
                    )}
                  </div>
                </Card>
              ))}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
